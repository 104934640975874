import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import SimpleDialog from './SimpleDialog';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  onToggle: () => void;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function AddPendingInvestorReplicationCycleRecord({ onToggle: onToggle, title, message, hoverText, disabled }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={hoverText || `Incluir investidor no ciclo de replicação.`} aria-label={hoverText || `Incluir investidor no ciclo de replicação.`}>
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <AddIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || `Deseja incluir investidor no ciclo de replicação?`}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onToggle}
        title={title || `Incluir investidor no ciclo de replicação`}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
