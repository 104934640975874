export const formatReplicationStatus = (value: string) => {
  switch (value) {
    case 'PENDING':
      return 'Pendente';
    case 'ACTIVE':
      return 'Ativa';
    case 'ENDED':
      return 'Finalizada';
    default:
      break;
  }
};
