import { useQueryParams } from '@elentari/core';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { AddRecord, DeleteRecord, Paper, TableContainer } from '../../components';
import AsyncSearchInput from '../../components/Formik/Forms/AsyncSearchInput';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import When from '../../components/When';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { managerRepository } from './hooks/managerRepository';
import { useManagers } from './hooks/useManagers';
import { ManagerDetail } from './types';

interface Props {
  list: ManagerDetail[];
  loading: boolean;
  totalCount: number;
  onDelete: (id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type ManagerQueryFilter = {
  firstName: { name: string; value: string };
  email: { name: string; value: string };
};

const ManagerFilter = () => {
  const { clear, push } = useQueryParams<ManagerQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: ManagerQueryFilter & { page: number }) => {
    push({
      ...values,
      firstName: values.firstName.name,
      email: values.email.name,
    });
  };

  return (
    <FilterWrap
      initialValues={{
        firstName: { name: '', value: '' },
        email: { name: '', value: '' },
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AsyncSearchInput name="firstName" label="Nome" url="/manager" labelField="firstName" />
        </Grid>
        <Grid item xs={6}>
          <AsyncSearchInput name="email" label="Email" url="/manager" labelField="email" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const ManagerTable = ({ list, loading, totalCount, onDelete, authorization }: Props) => {
  return (
    <>
      <ManagerFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="firstName" />
                  <TableCellHead label="E-mail" sort="email" />
                  <TableCellHead label="Papel" sort="role" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((manager) => (
                  <TableRow hover key={manager.id}>
                    <TableCell>{manager.firstName}</TableCell>
                    <TableCell>{manager.email}</TableCell>
                    <TableCell>{manager.role}</TableCell>
                    <When value={true} equals>
                      <DeleteRecord onDelete={() => onDelete(manager.id)} />
                    </When>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="manager" authorization={authorization} />
    </>
  );
};

const ManagerList = (props: any) => {
  const [state, actions] = useManagers();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleDelete(id: string) {
    await managerRepository.delete(id);
    actions.fetchPage({});
  }

  switch (state.tag) {
    case 'empty':
      return <ManagerTable onDelete={() => {}} totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <ManagerTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={handleDelete}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default ManagerList;
