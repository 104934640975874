import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useField, useFormikContext } from 'formik';
import { apiSauceInstance } from '../../../services/api';

interface Value {
  name: string;
  id: string;
  [key: string]: any;
}
interface Props {
  altValue?: string;
  endAdornment?: React.ReactNode;
  url: string;
  name: string;
  label: string;
  filters?: Record<string, any>;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  labelField: string | Array<string>;
}

const AsyncSearchInput: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Value[]>([]);
  const [field, meta, helper] = useField(props.name);
  const formik = useFormikContext();
  const loading = open && options?.length === 0;
  const value = typeof field.value === 'string' ? null : field.value;

  async function handleSearchOneFromApi(idFromEntity: string): Promise<any> {
    const response = await apiSauceInstance.get<any>(`${props.url}/${idFromEntity}`);
    const data = response.data;
    if (typeof props.labelField === 'string') {
      return formik.setFieldValue(props.name, {
        name: data[props.labelField],
        value: data.id,
      });
    } else {
      return formik.setFieldValue(props.name, {
        name: data[props.labelField[0]],
        value: data.id,
      });
    }
  }

  async function handleSearch(search?: string): Promise<void> {
    if (typeof props.labelField === 'string') {
      const response = await apiSauceInstance.get<any>(props.url, {
        [props.labelField]: search,
      });
      setOptions(
        response.data.edges.map((currentValue: any) => ({
          name: currentValue.node[props.labelField as string],
          value: props.altValue ? currentValue.node[props.altValue] : currentValue.node.id,
        })),
      );
    } else {
      const response = await apiSauceInstance.get<any>(props.url, {
        [props.labelField[0]]: search,
      });
      setOptions(
        response.data.edges?.map((currentValue: any) => ({
          name:
            currentValue.node[props.labelField[0]] + ' ' + currentValue.node[props.labelField[1]],
          value: props.altValue ? currentValue.node[props.altValue] : currentValue.node.id,
        })),
      );
    }
  }

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (typeof field.value === 'string' && field.value)
      !props.disabled && handleSearchOneFromApi(field.value);
  }, [field.value]);

  return (
    <Autocomplete
      open={open}
      disabled={props.disabled}
      onOpen={() => {
        setOpen(true);
        !props.disabled && handleSearch();
      }}
      onClose={() => setOpen(false)}
      onChange={(e, value) => {
        return helper.setValue(value);
      }}
      onInputChange={(_e, value) => !props.disabled && handleSearch(value)}
      onBlur={field.onBlur}
      getOptionSelected={(option, value) => option.name === value?.name}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      options={options || []}
      loading={loading}
      fullWidth={props.fullWidth}
      value={value}
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Sem opções"
      openText="Abrir"
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          label={props.label}
          name={props.name}
          variant={'outlined'}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncSearchInput;
