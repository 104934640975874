import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import MaskInput from '../../components/Formik/Forms/MaskInput';
import TextInput from '../../components/Formik/Forms/TextInput';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useTraderBinanceCredencials } from './hooks/useTraderBinanceCredencials';
import { TraderBinanceCredencialsDetail } from './types';

interface Props {
  list: TraderBinanceCredencialsDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type TraderQueryFilter = {
  firstName: string;
  email: string;
  cpf: string;
  status: string;
};

const TraderFilter = () => {
  const { clear, push } = useQueryParams<TraderQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: TraderQueryFilter & { page: number }) => {
    push({
      ...values,
      cpf: values.cpf.replaceAll(/\D/g, ''),
    });
  };

  return (
    <FilterWrap
      initialValues={{
        cpf: '',
        firstName: '',
        email: '',
        status: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput name="firstName" label="Primeiro Nome" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const TraderTable = ({ list, loading, totalCount, authorization }: Props) => {
  return (
    <>
      <TraderFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="firstName" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((trader) => (
                  <TableRow hover key={trader.id}>
                    <TableCell>
                      <LinkMD
                        component={Link}
                        to={`/professionals-binance-credencials/${trader.id}`}
                      >
                        {`${trader.lastName}`}
                      </LinkMD>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const TraderBinanceCredencialsList = () => {
  const [state, actions] = useTraderBinanceCredencials();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <TraderTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <TraderTable list={state.list} totalCount={state.totalCount} loading={state.loading} />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TraderBinanceCredencialsList;
