import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import When from './When';

interface SimpleDialogProps {
  open: boolean;
  buttonLabel: string;
  handleClose: () => void;
  primaryAction?: () => void;
  primaryActionButtonLabel: string;
  title: string;
  message: string;
}

function SimpleDialog({
  open,
  buttonLabel = 'Ok',
  handleClose,
  primaryAction,
  primaryActionButtonLabel,
  title,
  message,
}: SimpleDialogProps) {
  const handlePrimaryAction = () => {
    handleClose();
    primaryAction && primaryAction();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={primaryAction ? 'default' : 'primary'} autoFocus>
          {buttonLabel}
        </Button>
        <When value={!!primaryAction} equals={true}>
          <Button onClick={handlePrimaryAction} color="primary" autoFocus>
            {primaryActionButtonLabel}
          </Button>
        </When>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
