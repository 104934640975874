import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput';
import TextInput from '../../../components/TextInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { creditAccountOperationRepository } from '../hooks/creditAccountOperationRepository';
import { CreditAccountHistoryForm } from '../types';

const schema = yup.object().shape({
  userId: yup.object().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  balance: yup
    .number()
    .min(0.01, `${MessagesYup.MENSAGEM_VALOR_ZERO}`)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const CreditAccountOperationForm = () => {
  const history = useHistory();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<CreditAccountHistoryForm>(creditAccountOperationRepository.save);

  const handleSubmit = async (form: any) => {
    setLoading(true);
    await save({
      ...form,
      userId: form.userId.value,
      balance: Number((form.balance * Math.pow(10, 8)).toFixed(0)),
    });
    setLoading(false);
    handleGoBack();
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues = {
    balance: 0,
    userId: '',
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AsyncSearchInput
                  name="userId"
                  label="Investidor"
                  url="/users"
                  labelField={['firstName', 'lastName']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="balance" label="Lançamento" type="number" />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
