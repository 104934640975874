import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Paper } from '../../../components';
import DateInput from '../../../components/DateInput';
import EmailInput from '../../../components/Formik/Forms/Inputs/EmailInput';
import MaskInput from '../../../components/Formik/Forms/MaskInput';
import SelectInput from '../../../components/Formik/Forms/SelectInput';
import Spacer from '../../../components/Spacer';
import TextInput from '../../../components/TextInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { traderRepository } from '../hooks/traderRepository';
import { useTrader } from '../hooks/useTrader';
import { ITraderForm, Trader } from '../types';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  endColumn: {
    alignSelf: 'end',
    justifyContent: 'start',
  },
  uploadButton: {
    marginRight: '10px',
  },
}));

const schema = Yup.object().shape(
  {
    firstName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    lastName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    apiKey: Yup.string().when('secretKey', {
      is: (secretKey: string) => secretKey != '' && secretKey != null,
      then: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: Yup.string().nullable(),
    }),
    secretKey: Yup.string().when('apiKey', {
      is: (apiKey: string) => apiKey != '' && apiKey != null,
      then: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: Yup.string().nullable(),
    }),
    phoneRegion: Yup.string().trim().required('Obrigatório'),
    phone: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    email: Yup.string().email().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    cpf: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    taxRate: Yup.number()
      .min(1, MessagesYup.MENSAGEM_VALOR_MINIMO + ' 1')
      .max(40, MessagesYup.MENSAGEM_VALOR_MAXIMO + ' 40')
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    operationRate: Yup.number()
      .min(1, MessagesYup.MENSAGEM_VALOR_MINIMO + ' 1')
      .max(45, MessagesYup.MENSAGEM_VALOR_MAXIMO + ' 45')
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    leverage: Yup.number()
      .min(1, MessagesYup.MENSAGEM_VALOR_MINIMO + ' 1')
      .max(125, MessagesYup.MENSAGEM_VALOR_MAXIMO + ' 125')
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    expSince: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  },
  [['apiKey', 'secretKey']],
);

export const TraderForm = () => {
  const history = useHistory();
  const [traderState] = useTrader();
  const [, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const riskOptions = [
    { value: 'CONSERVATIVE', label: 'CONSERVATIVO' },
    { value: 'CONSERVATIVE_MODERATE', label: 'CONSERVATIVO MODERADO' },
    { value: 'MODERATE', label: 'MODERADO' },
    { value: 'MODERATE_AGGRESSIVE', label: 'MODERADO AGRESSIVO' },
    { value: 'AGGRESSIVE', label: 'AGRESSIVO' },
  ];

  const handleGoBack = () => {
    history.goBack();
  };

  const { save } = useEntitySaver<ITraderForm>(traderRepository.save);

  const initialValues = {
    email: '',
    firstName: '',
    phoneRegion: '',
    phone: '',
    lastName: '',
    cpf: '',
    birthDate: new Date(),
    apiKey: '',
    secretKey: '',
    walletAddress: '',
    description: '',
    taxRate: 0,
    expSince: new Date(),
    leverage: 0,
    operationRate: 0,
    cycleAvgTime: 0,
    risk: '',
    goalProfit: 0,
  };

  const formatData = (values: Trader) => {
    return {
      ...values,
      expSince: new Date(values.expSince),
      birthDate: new Date(values.birthDate),
    };
  };

  const handleSubmit = async (trader: ITraderForm) => {
    setLoading(true);
    const {
      id,
      email,
      walletAddress,
      apiKey,
      secretKey,
      birthDate,
      cpf,
      firstName,
      lastName,
      phoneRegion,
      phone,
      description,
      taxRate,
      expSince,
      leverage,
      operationRate,
      cycleAvgTime,
      risk,
      goalProfit,
    } = trader;
    await save({
      id,
      walletAddress,
      apiKey,
      secretKey,
      birthDate,
      email,
      firstName,
      lastName,
      phoneRegion,
      phone,
      cpf: cpf.replace(/[^\d]+/g, ''),
      description,
      taxRate,
      expSince: expSince,
      leverage,
      operationRate,
      cycleAvgTime,
      risk,
      goalProfit,
    });
    setLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        traderState.tag === 'with-data' ? formatData(traderState.entity) : initialValues
      }
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={6} justifyContent={'space-between'}>
              <Grid item xs={6} className={classes.endColumn}></Grid>
              <Grid item>
                <TextInput name="status" disabled label="Status do Trader" debounce />
              </Grid>
            </Grid>
            <Spacer y={4} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput name="firstName" label="Primeiro Nome" />
              </Grid>
              <Grid item xs={4}>
                <TextInput name="lastName" label="Apelido" />
              </Grid>
              <Grid item xs={1}>
                <MaskInput name="phoneRegion" label="Região" mask="+99" />
              </Grid>
              <Grid item xs={3}>
                <MaskInput name="phone" label="Telefone Celular" mask="(99) 99999-9999" />
              </Grid>
              <Grid item xs={4}>
                <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
              </Grid>
              <Grid item xs={4}>
                <DateInput label="Data de Nascimento" name="birthDate" />
              </Grid>
              <Grid item xs={4}>
                <EmailInput name="email" label="Email" />
              </Grid>
              <Grid item xs={6}>
                <SelectInput options={riskOptions} name="risk" label="Nível de Risco" />
              </Grid>
              <Grid item xs={3}>
                <TextInput name="cycleAvgTime" label="Tempo médio de ciclo" type="number" />
              </Grid>
              <Grid item xs={3}>
                <TextInput name="goalProfit" label="Alvo de Lucro (%)" type="number" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput name="apiKey" label="Api Key" disabled debounce />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="secretKey" label="Secret Key" disabled debounce />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  name="walletAddress"
                  label="Endereço da Carteira USDT"
                  disabled
                  debounce
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput name="leverage" label="Alavancagem" type="number" />
              </Grid>
              <Grid item xs={2}>
                <DateInput name="expSince" label="Tempo de Experiência" />
              </Grid>
              <Grid item xs={2}>
                <TextInput name="taxRate" label="Taxa do Profissional (%)" type="number" />
              </Grid>
              <Grid item xs={2}>
                <TextInput name="operationRate" label="Taxa Operacional (%)" type="number" />
              </Grid>
              <Grid item xs={12}>
                <TextInput name="description" label="Descrição" minLength={13} multiple />
              </Grid>
            </Grid>

            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
