export const formatStatusUser = (value: string) => {
  switch (value) {
    case 'PENDING':
      return 'Pendente';
    case 'DENIED':
      return 'Reprovado';
    case 'APPROVED':
      return 'Aprovado';
    case 'OFF':
      return 'Desligado';
    case 'HOMOLOGATION':
      return 'Em homologação';
    default:
      break;
  }
};
