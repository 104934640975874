import { Line, LineChart } from 'recharts';
interface miniChartsProps {
  data: {
    uv: number;
  }[];
}

export default function MinichartsCycle(props: miniChartsProps) {
  return (
    <>
      <LineChart width={80} height={30} data={props.data}>
        <Line dot={false} type="monotone" dataKey="uv" stroke="#F3BA2F" yAxisId={0} />
      </LineChart>
    </>
  );
}
