import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { AddressForm } from './AddressForm';
import { useAddress } from '../hooks/useAddress';

export function AddressMasterDetail() {
  const [addressState, addressActions] = useAddress();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      addressState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(addressActions.fetch, addressActions.reset);
  return handleStateErrorsToRender(addressState, <AddressForm />);
}
