import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { IManagerForm, ManagerDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const managerRepository = makeRepository<
  EdgesPage<ManagerDetail>,
  IManagerForm,
  ManagerDetail,
  string
>({ resource: 'manager', mapQueryParams }, apiSauceInstance);
