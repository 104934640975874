import { ChangeEvent, FormEvent, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  passwordField: {
    width: '100%',
  },
}));

type Props = FieldRenderProps<string> & TextFieldProps;

const CustomPasswordField = ({ input, id, meta, values, label, onChange, ...props }: Props) => {
  if (!input || !meta) {
    throw Error('Sorry my friend. Did you forget field from final form?');
  }

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    input.onChange(event);
  };

  return (
    <TextField
      {...input}
      {...props}
      label={label}
      id={id}
      className={clsx(classes.passwordField)}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      onChange={handleChange}
      InputProps={{
        type: showPassword ? 'text' : 'password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomPasswordField;
