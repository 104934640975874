import { Box, Grid, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useParams } from 'react-router-dom';
import { Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import { formatPercent } from '../../utils/formatPercent';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useReplicationCyclesMetrics } from './hooks/useTraders';
import { TraderReplicationMetricsDetail } from './types';

interface Props {
  list: TraderReplicationMetricsDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

export const ReplicationCycleMetricsTable = ({ list, loading, totalCount }: Props) => {
  return (
    <>
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Qtd. de Ciclos" sort="" />
                  <TableCellHead label="Qtd. Dias do Ciclo Média" sort="" />
                  <TableCellHead label="Carteira Inicial Acumulada" sort="" />
                  <TableCellHead label="Carteira Final Acumulada" sort="" />
                  <TableCellHead label="Ganho Acumulado" sort="" />
                  <TableCellHead label="% Ganho Acumulado" sort="" />
                  <TableCellHead label="V/D" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((metrics) => (
                  <TableRow selected>
                    <TableCell>{metrics.countTotalCycles}</TableCell>
                    <TableCell>{metrics.numberOfDaysAvg}</TableCell>
                    <TableCell>{formatedValueUSDT(metrics.initialBalance)}</TableCell>
                    <TableCell>{formatedValueUSDT(metrics.finalBalance)}</TableCell>
                    <TableCell>{formatedValueUSDT(metrics.amoutProfitTraderCycle)}</TableCell>
                    <TableCell>{formatPercent(Number(metrics.traderPercentRentability))}</TableCell>
                    <TableCell>
                      {formatPercent(
                        (100 * metrics.countProfitableCycle) / metrics.countTotalCycles,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

export const TraderReplicationMetrics = () => {
  const [state, actions] = useReplicationCyclesMetrics();
  const params = useParams<{ id: string; childId: string }>();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  switch (state.tag) {
    case 'empty':
      return <ReplicationCycleMetricsTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <Grid container>
          <Grid item md={8}>
            <ReplicationCycleMetricsTable
              list={state.list}
              totalCount={state.totalCount}
              loading={state.loading}
            />
          </Grid>
          <Grid item md={4} justifyContent="center">
            <GaugeChart
              id="gauge-chart6"
              animate={false}
              nrOfLevels={8}
              percent={Number(state.list[0].countProfitableCycle / state.list[0].countTotalCycles)}
              hideText
              colors={['#FF573D', '#FFC22D', '#93C548']}
            />
          </Grid>
        </Grid>
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};
