import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { AddRecord, Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import When from '../../components/When';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useFuturesAccountList } from './hooks/useUsers';
import { UserFuturesAccountDetail } from './types';

interface Props {
  list: UserFuturesAccountDetail[];
  loading: boolean;
  totalCount: number;
  onDelete: (parentId: string, id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
  };
}

export const FuturesAccountTable = ({
  list,
  loading,
  totalCount,
  onDelete,
  authorization,
}: Props) => {
  const location = useLocation();
  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Valor em Carteira" sort="" />
                  <When value={!authorization || authorization.canDelete} equals>
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((futuresAccount) => (
                  <TableRow hover key={futuresAccount.address}>
                    <TableCell>{formatedValueUSDT(Number(futuresAccount.balance))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={location.pathname} authorization={authorization} />
    </>
  );
};

export const FuturesAccount = () => {
  const [state, actions] = useFuturesAccountList();
  const params = useParams<{ id: string; childId: string }>();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const urlParam = new URLSearchParams(search);
      actions.fetchPage(parse(urlParam.toString()), Number(params.id));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return (
        <FuturesAccountTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
          authorization={{ canCreate: false, canDelete: false }}
        />
      );
    case 'with-data':
      return (
        <FuturesAccountTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={() => {}}
          authorization={{ canCreate: false, canDelete: false }}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data} />;
    default:
      return <HandleErrorPage state={null} />;
  }
};
