import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, ApproveRecord, DenyRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import MaskInput from '../../components/Formik/Forms/MaskInput';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import TextInput from '../../components/Formik/Forms/TextInput';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { apiSauceInstance } from '../../services/api';
import { formatCpfCnpj } from '../../utils/format-cpf';
import { formatStatusUser } from '../../utils/formatStatusUser';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useUsers } from './hooks/useUsers';
import { status, UserDetail } from './types';

interface Props {
  list: UserDetail[];
  loading: boolean;
  totalCount: number;
  onDeny: (id: UserDetail) => void;
  onApprove: (id: UserDetail) => void;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type UserQueryFilter = {
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  cpf: string;
  status: string;
};

const UserFilter = () => {
  const { clear, push } = useQueryParams<UserQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: UserQueryFilter & { page: number }) => {
    push({
      ...values,
      cpf: values.cpf.replaceAll(/\D/g, ''),
    });
  };

  return (
    <FilterWrap
      initialValues={{
        cpf: '',
        type: '',
        firstName: '',
        lastName: '',
        email: '',
        status: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextInput name="firstName" label="Primeiro Nome" />
        </Grid>
        <Grid item xs={3}>
          <TextInput name="lastName" label="Sobrenome" />
        </Grid>
        <Grid item xs={2}>
          <TextInput name="email" label="Email" />
        </Grid>
        <Grid item xs={2}>
          <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
        </Grid>
        <Grid item xs={2}>
          <SelectInput options={status} name="status" label="Status" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const UserTable = ({
  list,
  loading,
  totalCount,
  onDeny,
  onApprove,
  authorization,
}: Props) => {
  return (
    <>
      <UserFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="firstName" />
                  <TableCellHead label="E-mail" sort="email" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="Indicado por" sort="" />
                  <TableCellHead label="Situação" sort="status" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/users/${user.id}`}>
                        {`${user.firstName} ${user.lastName}`}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatCpfCnpj(user.cpf)}</TableCell>
                    <TableCell>{`${
                      user.referrerFK
                        ? user.referrerFK.firstName + ' ' + user.referrerFK.lastName
                        : ' '
                    }`}</TableCell>
                    <TableCell>{formatStatusUser(user.status)}</TableCell>
                    <DenyRecord
                      onDeny={() => onDeny(user)}
                      disabled={user.status == 'OFF' || user.status == 'DENIED'}
                    />
                    <ApproveRecord
                      onApprove={() => onApprove(user)}
                      disabled={user.status == 'OFF' || user.status == 'APPROVED'}
                    />{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="users" authorization={authorization} />
    </>
  );
};

const UserList = () => {
  const [state, actions] = useUsers();
  const [, snackbarActions] = useSnackbar();
  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleDeny(user: UserDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`users/${user.id}/deny`, {
      status: 'DENIED',
    });
    if (res.ok) {
      snackbarActions.setMessage('Usuário Reprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleApprove(user: UserDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`users/${user.id}/approve`, {
      status: 'APPROVED',
    });
    if (res.ok) {
      snackbarActions.setMessage('Usuário Aprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return (
        <UserTable onApprove={() => {}} onDeny={() => {}} totalCount={0} list={[]} loading={true} />
      );
    case 'with-data':
      return (
        <UserTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onApprove={handleApprove}
          onDeny={handleDeny}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default UserList;
