import { EdgesPage } from '@elentari/core/types';
import { makeSubRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { TraderReplicationCycleDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const replicationCycleRepository = makeSubRepository<
  EdgesPage<TraderReplicationCycleDetail>,
  { id: number | undefined },
  TraderReplicationCycleDetail,
  number,
  number
>({ resource: 'professionals/:id/replication-cycles', mapQueryParams }, apiSauceInstance);
