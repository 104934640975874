import { Tooltip } from '@material-ui/core';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import WorkIcon from '@mui/icons-material/Work';

export const FormatEndModeIcons = (value: string, date: string) => {
  const renderIcon = (value: string) => {
    switch (value) {
      case 'WORK_CYCLE':
        return (
          <Tooltip title={`Fim do Ciclo de Trabalho em: ${date}`}>
            <WorkIcon />
          </Tooltip>
        );
      case 'GOAL_PROFIT':
        return (
          <Tooltip title={`Alvo Atingido em: ${date}`}>
            <TrackChangesIcon />
          </Tooltip>
        );
      case 'ONGOING':
        return (
          <Tooltip title={`Em Andamento em: ${date}`}>
            <FlightTakeoffIcon />
          </Tooltip>
        );
      case 'USER_CANCEL_REPLICATION':
        return (
          <Tooltip title={`Cancelado pelo Usuário em: ${date}`}>
            <ParaglidingIcon />
          </Tooltip>
        );
      default:
        return null;
    }
  };
  return renderIcon(value);
};
