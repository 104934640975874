import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import * as dateFns from 'date-fns';
import { parse } from 'qs';
import { useEffect } from 'react';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import { InactivateReplicationRecord } from '../../components/InactivateReplicationRecord';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import TextInput from '../../components/TextInput';
import { apiSauceInstance } from '../../services/api';
import { formatDate } from '../../utils/format-date';
import { formatReplicationStatus } from '../../utils/formatReplicationStatus';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useReplications } from './hooks/useReplications';
import { ReplicationDetail } from './types';

interface Props {
  list: ReplicationDetail[];
  loading: boolean;
  totalCount: number;
  onInactivate: (id: number) => void;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type ReplicationQueryFilter = {
  investor: string;
  trader: string;
  startDate: string;
  endDate: string;
  isActive: number | undefined;
};

const ReplicationFilter = () => {
  const { clear, push } = useQueryParams<ReplicationQueryFilter>();

  const type = [
    {
      label: 'Pendente',
      value: 'PENDING',
    },
    {
      label: 'Ativa',
      value: 'ACTIVE',
    },
    {
      label: 'Finalizada',
      value: 'ENDED',
    },
  ];

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: ReplicationQueryFilter & { page: number }) => {
    if (values.endDate && values.startDate) {
      const dateFormatted = dateFns.parse(String(values.endDate), 'dd/MM/yyyy', new Date());
      return push({
        ...values,
        endDate: dateFormatted.toISOString(),
        startDate: dateFormatted.toISOString(),
      });
    }
    push({
      ...values,
      endDate: undefined,
      startDate: undefined,
    });
  };

  return (
    <FilterWrap
      initialValues={{
        startDate: '',
        endDate: '',
        trader: '',
        investor: '',
        isActive: undefined,
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput name="investor" label="Investidor" />
        </Grid>
        <Grid item xs={4}>
          <TextInput name="trader" label="Trader" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput options={type} name="status" label="Status" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const ReplicationTable = ({
  list,
  loading,
  totalCount,
  onInactivate: onInactivate,
  authorization,
}: Props) => {
  return (
    <>
      <ReplicationFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Investidor" sort="investorId" />
                  <TableCellHead label="Trader" sort="traderId" />
                  <TableCellHead label="Data de Início" sort="startDate" />
                  <TableCellHead label="Data de Término" sort="endDate" />
                  <TableCellHead label="Status" sort="" />
                  <TableCellHead label="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replication) => (
                  <TableRow hover key={replication.id}>
                    <TableCell>{`${replication.investorFK.firstName} ${replication.investorFK.lastName}`}</TableCell>
                    <TableCell>{`${replication.traderFK.firstName} ${replication.traderFK.lastName}`}</TableCell>
                    <TableCell>{formatDate(replication.startDate)}</TableCell>
                    <TableCell>
                      {replication.endDate ? formatDate(replication.endDate) : ''}
                    </TableCell>
                    <TableCell>{formatReplicationStatus(replication.status)}</TableCell>
                    <InactivateReplicationRecord
                      onInactivate={() => onInactivate(replication.id)}
                      disabled={!(replication.status == 'ACTIVE')}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="replication" authorization={authorization} />
    </>
  );
};

const ReplicationList = (props: any) => {
  const [state, actions] = useReplications();
  const [, snackbarActions] = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleInactivate(id: number) {
    const res: ApiResponse<any> = await apiSauceInstance.post(`replication/${id}/stop-replication`);
    if (res.ok) {
      snackbarActions.setMessage('Replicação pausada com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return <ReplicationTable onInactivate={() => {}} totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <ReplicationTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onInactivate={handleInactivate}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default ReplicationList;
