import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { UserBinanceCredencialsForm } from './UserBinanceCredencialsForm';
import { useUserBinanceCredencial } from '../hooks/useUserBinanceCredencial';

export function UserBinanceCredencialsMasterDetail() {
  const [userBinanceCredencialState, userBinanceCredencialAction] = useUserBinanceCredencial();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      userBinanceCredencialState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(userBinanceCredencialAction.fetch, userBinanceCredencialAction.reset);
  return handleStateErrorsToRender(userBinanceCredencialState, <UserBinanceCredencialsForm />);
}
