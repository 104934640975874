import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { AddRecord, Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import When from '../../components/When';
import { formatDate } from '../../utils/format-date';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useSupportList } from './hooks/useUsers';
import { UserSupportDetail } from './types';

interface Props {
  list: UserSupportDetail[];
  loading: boolean;
  totalCount: number;
  onDelete: (parentId: string, id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
  };
}

export const SupportTable = ({ list, loading, totalCount, onDelete, authorization }: Props) => {
  const location = useLocation();
  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Data do atendimento" sort="createdAt" />
                  <TableCellHead label="Atendente" sort="managerId" />
                  <TableCellHead label="Comentário do Atendimento" sort="support" />
                  <When value={!authorization || authorization.canDelete} equals>
                    <TableCell></TableCell>
                  </When>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((support) => (
                  <TableRow hover key={support.id}>
                    <TableCell>{formatDate(support.createdAt)}</TableCell>
                    <TableCell>{`${
                      support.managerFK ? support.managerFK.firstName : ' '
                    }`}</TableCell>
                    <TableCell>{support.support}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={location.pathname} authorization={authorization} />
    </>
  );
};

export const Support = () => {
  const [state, actions] = useSupportList();
  const params = useParams<{ id: string; childId: string }>();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const urlParam = new URLSearchParams(search);
      actions.fetchPage(parse(urlParam.toString()), Number(params.id));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <SupportTable onDelete={() => {}} totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <SupportTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={() => {}}
          authorization={{ canCreate: true, canDelete: true }}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data} />;
    default:
      return <HandleErrorPage state={null} />;
  }
};
