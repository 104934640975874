import { useSnackbar } from '@elentari/core';
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Avatar, Button, CircularProgress, DialogActions, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Paper } from '../../../components';
import DateInput from '../../../components/DateInput';
import ChekboxInput from '../../../components/Formik/Forms/ChekboxInput';
import EmailInput from '../../../components/Formik/Forms/Inputs/EmailInput';
import MaskInput from '../../../components/Formik/Forms/MaskInput';
import TextInput from '../../../components/TextInput';
import Spacer from '../../../components/Spacer';
import logo from '../../../images/whatsapp.svg';
import { formatStatusUser } from '../../../utils/formatStatusUser';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { saveUpload, userRepository } from '../hooks/userRepository';
import { useUser } from '../hooks/useUser';
import { IUserForm, User } from '../types';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  endColumn: {
    alignSelf: 'end',
    justifyContent: 'start',
  },
  uploadButton: {
    marginRight: '10px',
  },
}));

const schema = Yup.object().shape(
  {
    goalProfit: Yup.string().nullable().min(0),
    firstName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    apiKey: Yup.string().when('secretKey', {
      is: (secretKey: string) => secretKey,
      then: Yup.string().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: Yup.string().nullable(),
    }),
    secretKey: Yup.string().when('apiKey', {
      is: (apiKey: string) => apiKey,
      then: Yup.string().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: Yup.string().nullable(),
    }),
    phoneRegion: Yup.string().trim().required('Obrigatório'),
    phone: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    lastName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    email: Yup.string().email().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    cpf: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  },
  [['apiKey', 'secretKey']],
);

export const UserForm = () => {
  const history = useHistory();
  const [userState] = useUser();
  const [, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [avatarPreview, setAvatarPreview] = useState<string>('');
  const classes = useStyles();
  const [render, setrender] = useState<boolean>(false);
  const [, snackbarActions] = useSnackbar();

  const handleGoBack = () => {
    history.goBack();
  };

  const { save } = useEntitySaver<IUserForm>(userRepository.save);

  const initialValues = {
    email: '',
    firstName: '',
    phoneRegion: '',
    phone: '',
    lastName: '',
    cpf: '',
    birthDate: new Date(),
    limit: false,
    test: false,
    profilePhoto: '',
    password: '',
    apiKey: '',
    secretKey: '',
    goalProfit: undefined,
    walletAddress: '',
    referrerfirstName: '',
    createdAt: '',
  };

  const formatData = (values: User) => {
    if (values.profilePhoto && !render) {
      setAvatarPreview(values.profilePhoto);
      setrender(true);
    }
    return {
      ...values,
      birthDate: new Date(values.birthDate),
      referrerfirstName: values.referrerFK
        ? values.referrerFK.firstName + ' ' + values.referrerFK.lastName
        : '',
      createdAt: new Date(values.createdAt),
      status: formatStatusUser(values.status),
    };
  };

  const handleSubmit = async (user: IUserForm) => {
    setLoading(true);
    let fileName;
    if (file) {
      const response = await saveUpload({ file });
      if (response && response.ok) {
        fileName = response.info.Location;
      }
    }
    const {
      walletAddress,
      apiKey,
      secretKey,
      birthDate,
      cpf,
      email,
      firstName,
      lastName,
      goalProfit,
      test,
      phoneRegion,
      phone,
      limit,
      id,
    } = user;
    await save({
      walletAddress,
      apiKey,
      secretKey,
      birthDate,
      email,
      firstName,
      lastName,
      test,
      phoneRegion,
      phone: phone.replace(/[^\d]+/g, ''),
      limit,
      goalProfit: goalProfit ? goalProfit : null,
      id,
      cpf: cpf.replace(/[^\d]+/g, ''),
      profilePhoto: fileName,
    });
    setLoading(false);
  };

  const onFileChange = (fileChangeEvent: any) => {
    const [file] = fileChangeEvent.target.files;
    if (file) {
      setFile(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleWppIconClick = (phone: string | undefined) => {
    if (phone && phone.length === 11) {
      const url = `https://api.whatsapp.com/send?phone=55${phone}`;
      window.open(url, '_blank');
    } else {
      snackbarActions.setMessage('Número de telefone inválido');
    }
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={userState.tag === 'with-data' ? formatData(userState.entity) : initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={6}>
              <Grid item xs={2}>
                <Avatar className={classes.large} src={avatarPreview} variant="rounded" />
              </Grid>
            </Grid>
            <Grid container spacing={6} justifyContent={'space-between'}>
              <Grid item className={classes.endColumn}>
                <Button variant="outlined" component="label" startIcon={<CloudUploadOutlined />}>
                  Escolha a Imagem de Perfil
                  <input
                    name="avatar"
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={onFileChange}
                  />
                </Button>
              </Grid>
              <Grid item>
                <TextInput name="status" disabled label="Status do Usuário" debounce />
              </Grid>
            </Grid>
            <Spacer y={4} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput name="firstName" label="Primeiro Nome" />
              </Grid>
              <Grid item xs={4}>
                <TextInput name="lastName" label="Ultimo Nome" />
              </Grid>
              <Grid item xs={2}>
                <DateInput label="Data de Nascimento" name="birthDate" />
              </Grid>
              <Grid item xs={2}>
                <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
              </Grid>
              <Grid item xs={3}>
                <EmailInput name="email" label="Email" />
              </Grid>
              <Grid item xs={1}>
                <MaskInput name="phoneRegion" label="Região" mask="+99" />
              </Grid>
              <Grid item xs={2}>
                <MaskInput
                  name="phone"
                  label="Telefone Celular"
                  mask="(99) 99999-9999"
                  endAdornment={
                    <Button onClick={() => handleWppIconClick(values.phone.replace(/[^\d]+/g, ''))}>
                      <img src={logo} height={25} width={25} alt="logo wpp" />{' '}
                    </Button>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <DateInput label="Data da Criação" disabled name="createdAt" />
              </Grid>
              <Grid item xs={4}>
                <TextInput name="referrerfirstName" disabled label="Indicado Por" debounce />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  name="walletAddress"
                  disabled
                  label="Endereço da Carteira USDT"
                  debounce
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput name="apiKey" label="Api Key" disabled debounce />
              </Grid>
              <Grid item xs={3}>
                <TextInput name="secretKey" label="Secret Key" disabled debounce />
              </Grid>
              <Grid item xs={2}>
                <TextInput name="goalProfit" label="% Lucro" type="number" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ChekboxInput name="test" label="Conta Teste?" />
                <ChekboxInput name="limit" label="Limitada?" />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
