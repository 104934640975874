import { useSnackbar } from '@elentari/core';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { Paper } from '../../../components';
import TextInput from '../../../components/TextInput';
import api from '../../../services/api';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { useTraderBinanceCredencial } from '../hooks/useTraderBinanceCredencial';
import {
  ITraderBinanceCredencialsForm,
  TraderBinanceCredencials,
  TraderBinanceCredencialsDetail,
} from '../types';

export const TraderBinanceCredencialsForm = () => {
  const history = useHistory();
  const [, snackbarActions] = useSnackbar();
  const [, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [traderState] = useTraderBinanceCredencial();

  const schema = Yup.object().shape(
    {
      apiKey: Yup.string().when('secretKey', {
        is: (secretKey: string) => secretKey != '' && secretKey != null,
        then: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        otherwise: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      }),
      secretKey: Yup.string().when('apiKey', {
        is: (apiKey: string) => apiKey != '' && apiKey != null,
        then: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        otherwise: Yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      }),
    },
    [['apiKey', 'secretKey']],
  );

  const formatData = (values: TraderBinanceCredencialsDetail) => {
    return {
      lastName: values.lastName,
      apiKey: '',
      secretKey: '',
    };
  };

  const handleSubmit = async (data: ITraderBinanceCredencialsForm) => {
    setLoading(true);
    const response = await api.patchTraderBinanceCredencials(Number(params.id), data);
    if (response.ok) {
      snackbarActions.setMessage(`Sucesso ao salvar credenciais`);
    } else {
      snackbarActions.setMessage(`Não foi possível salvar credenciais`);
    }
    setLoading(false);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues: Partial<TraderBinanceCredencials> = {
    lastName: '',
    apiKey: '',
    secretKey: '',
  };

  return (
    <Formik
      validate={yupValidation(schema)}
      enableReinitialize
      initialValues={
        traderState.tag === 'with-data' ? formatData(traderState.entity) : initialValues
      }
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="lastName" label="Profissional" disabled />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="apiKey" label="Api Key" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="secretKey" label="Secret Key" />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
