import { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { Item } from './AppWrap/MenuItems';

type RouterByMenuProps = {
  menuItems: Item[];
  children?: ReactNode;
};

export const RouteByMenu = ({ menuItems, children }: RouterByMenuProps) => {
  return (
    <>
      {menuItems.map((item) => (
        <div key={item.name}>
          {item.group === false ? (
            <>
              {item.list && <Route path={item.pathname} exact component={item.list} />}
              {item.detail && <Route path={`${item.pathname}/:id`} component={item.detail} />}
            </>
          ) : (
            item.items.map((subItem) => (
              <div key={subItem.name}>
                {subItem.list && <Route path={subItem.pathname} exact component={subItem.list} />}
                {subItem.detail && (
                  <Route path={`${subItem.pathname}/:id`} component={subItem.detail} />
                )}
              </div>
            ))
          )}
        </div>
      ))}
      {children}
    </>
  );
};
