import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import SelectInput from '../../../components/Formik/Forms/SelectInput';
import TextInput from '../../../components/TextInput';
import { urlRegex } from '../../../utils/urlValidation';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { useVideo } from '../hooks/useVideo';
import { videoRepository } from '../hooks/videoRepository';
import { Video, VideoType, videoTypeOptions } from '../types';

const schema = yup.object().shape({
  url: yup
    .string()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .matches(urlRegex, MessagesYup.INVALID_URL),
  type: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const VideoForm = () => {
  const history = useHistory();
  const [videoState] = useVideo();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<Video>(videoRepository.save);

  const handleSubmit = async (video: any) => {
    setLoading(true);
    await save(video);
    setLoading(false);
  };

  const formatData = (values: Video) => {
    return values;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues: Partial<Video> = {
    url: undefined,
    type: VideoType.FUTURES_ACCOUNT,
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={videoState.tag === 'with-data' ? formatData(videoState.entity) : initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextInput name="url" label="URL do Vídeo" />
              </Grid>
              <Grid item xs={3}>
                <SelectInput name="type" label="Tipo" options={videoTypeOptions} />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
