import { makeUseList } from '@elentari/core';
import { makeUseSecondaryList } from '@elentari/core/hooks/makeUseList';
import { UserFuturesAccountDetail, UserSupportDetail } from '../types';
import { futuresRepository } from './futuresAccountRepository';
import { supportRepository } from './supportRepository';
import { userRepository } from './userRepository';

export const useUsers = makeUseList(userRepository.fetch);

export const useSupportList = makeUseSecondaryList<UserSupportDetail>(supportRepository.fetch);

export const useFuturesAccountList = makeUseSecondaryList<UserFuturesAccountDetail>(
  futuresRepository.fetch,
);
