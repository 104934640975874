import { CrudRoute, CrudTabs, useTabsNavigation } from '@elentari/components/crud/CrudTabs';
import { FuturesAccount } from '../FuturesAccount';
import { Support } from '../SupportList';
import { SupportForm } from './SupportForm';
import { UserForm } from './UserForm';

export const UserTabs = () => {
  const hook = useTabsNavigation({
    mainPath: 'users',
    tabs: [
      {
        value: '',
        label: 'Usuário',
      },
      {
        value: 'futures-account',
        label: 'Conta de Futuros',
      },
      { value: 'support', label: 'Atendimento ao cliente' },
    ],
  });
  return (
    <CrudTabs {...hook} disableBadge withPaper withDivider>
      <CrudRoute name="" isForm render={() => <UserForm />} />
      <CrudRoute name="futures-account" render={() => <FuturesAccount />} />
      <CrudRoute name="support" render={() => <Support />} />
      <CrudRoute isForm name="support" render={() => <SupportForm />} />
    </CrudTabs>
  );
};
