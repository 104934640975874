import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { IUserBinanceCredencialsForm, UserBinanceCredencialsDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const userBinanceCredencialsRepository = makeRepository<
  EdgesPage<UserBinanceCredencialsDetail>,
  IUserBinanceCredencialsForm,
  UserBinanceCredencialsDetail,
  number
>({ resource: 'users', mapQueryParams }, apiSauceInstance);
