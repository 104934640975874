import { useQueryParams } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import TextInput from '../../components/TextInput';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useFaqs } from './hooks/useFaqs';
import { FaqDetail } from './types';

interface Props {
  list: FaqDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type FaqQueryFilter = {
  title: string;
};

const FaqFilter = () => {
  const { clear, push } = useQueryParams<FaqQueryFilter>();

  const type = [
    {
      label: 'Sim',
      value: 1,
    },
    {
      label: 'Não',
      value: 0,
    },
  ];

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: FaqQueryFilter & { page: number }) => {
    push(values);
  };

  return (
    <FilterWrap
      initialValues={{
        title: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput name="title" label="Título" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const FaqTable = ({ list, loading, totalCount, authorization }: Props) => {
  return (
    <>
      <FaqFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Título" sort="title" />
                  <TableCellHead label="Descrição" sort="description" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((faq) => (
                  <TableRow hover key={faq.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/faqs/${faq.id}`}>
                        {faq.title}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{faq.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="faqs" authorization={authorization} />
    </>
  );
};

const FaqList = (props: any) => {
  const [state, actions] = useFaqs();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <FaqTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return <FaqTable list={state.list} totalCount={state.totalCount} loading={state.loading} />;
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default FaqList;
