import { makeUseFetchOne, makeUseFetchOneByParent } from '@elentari/core/hooks/makeUseFetchOne';
import { UserDetail, UserSupportDetail } from '../types';
import { supportRepository } from './supportRepository';
import { userRepository } from './userRepository';

export const useUser = makeUseFetchOne<UserDetail, number>({
  fetch: userRepository.fetchOne,
});

export const useSupport = makeUseFetchOneByParent<UserSupportDetail, number, number>({
  fetch: supportRepository.fetchOne,
});
