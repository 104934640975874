import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { ITraderForm, TraderDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const traderRepository = makeRepository<
  EdgesPage<TraderDetail>,
  ITraderForm,
  TraderDetail,
  number
>({ resource: 'professionals', mapQueryParams }, apiSauceInstance);

interface Data {
  file?: File;
}

export const saveUpload = async (data: Data) => {
  const formData = new FormData();
  if (data.file) formData.append('file', data.file);
  const response = await apiSauceInstance.post<any>('/upload/file', formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
  const { data: info, ok, status } = response;

  if (info && ok) {
    return {
      info,
      ok,
      status,
    };
  }
  return null;
};

export const getUpload = async (id: string) => {
  const response = await apiSauceInstance.get<any>(`/upload/file/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
  const { data: info, ok, status } = response;

  if (info && ok) {
    return {
      info,
      ok,
      status,
    };
  }
  return null;
};
