import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { formatPercent } from '../../utils/formatPercent';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import MinichartsCycle from '../../utils/MinichartsCycle';
import { useReplicationCycles } from './hooks/useTraders';
import { TraderReplicationCycleDetail } from './types';

interface Props {
  list: TraderReplicationCycleDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

export const ReplicationCycleTable = ({ list, loading, totalCount }: Props) => {
  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Identificador do Ciclo" sort="" />
                  <TableCellHead label="Qtd. Dias do Ciclo" sort="" />
                  <TableCellHead label="Carteira Inicial" sort="" />
                  <TableCellHead label="Carteira Final" sort="" />
                  <TableCellHead label="Ganho" sort="" />
                  <TableCellHead label="% Ganho" sort="" />
                  <TableCellHead label="V/D" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replicationCycle) => (
                  <TableRow key={replicationCycle.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/replication-cycle/${replicationCycle.id}`}>
                        {replicationCycle.semId}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{replicationCycle.numberOfDays}</TableCell>
                    <TableCell>{formatedValueUSDT(replicationCycle.initialBalance)}</TableCell>
                    <TableCell>{formatedValueUSDT(replicationCycle.finalBalance)}</TableCell>
                    <TableCell>
                      {formatedValueUSDT(replicationCycle.amoutProfitTraderCycle)}
                    </TableCell>
                    <TableCell>
                      {formatPercent(Number(replicationCycle.traderPercentRentability))}
                    </TableCell>
                    <TableCell>{replicationCycle.isProfitableCycle ? 'V' : 'D'}</TableCell>
                    <TableCell>{<MinichartsCycle data={replicationCycle.minichart} />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const TraderReplicationCycleList = (props: any) => {
  const [state, actions] = useReplicationCycles();
  const params = useParams<{ id: string; childId: string }>();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  switch (state.tag) {
    case 'empty':
      return <ReplicationCycleTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <ReplicationCycleTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TraderReplicationCycleList;
