import { drawerWidth, drawerClosedWidth } from './AppWrapStyles';

const MenuItemsStyles = (theme: any) => ({
  menuColor: {
    color: theme.palette.grey[500],
  },
  menuColorActive: {
    color: theme.palette.common.white,
  },
  menuColorActiveLight: {
    color: theme.palette.grey[900],
  },
  divider: {
    backgroundColor: '#9D9C9D',
  },
  active: {
    borderLeft: `solid 3px ${theme.palette.primary.main}`,
    borderImageSlice: 1,
  },
  popper: {
    minWidth: 200,
    marginLeft: drawerClosedWidth - drawerWidth + 0.5,
    zIndex: 1500,
  },
  popperTitle: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  popperArrow: {
    position: 'absolute',
    left: -5,
    top: '50%',
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderRight: '5px solid white',
    zIndex: 1500,
  },
  menuList: {
    marginTop: -10,
  },
  menuItem: {
    fontSize: 14,
  },
});

export default MenuItemsStyles;
