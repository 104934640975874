import { EdgesPage } from '@elentari/core/types';
import { makeSubRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { ReplicationCycleInvestorsDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const replicationLogsRepository = makeSubRepository<
  EdgesPage<ReplicationCycleInvestorsDetail>,
  { id: number | undefined },
  ReplicationCycleInvestorsDetail,
  number,
  number
>({ resource: 'replication-cycle/:id/investors', mapQueryParams }, apiSauceInstance);
