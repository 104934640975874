import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useContext, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import PasswordField from '../../components/PasswordField';
import Snackbar from '../../components/Snackbar';
import Spacer from '../../components/Spacer';
import TextField from '../../components/TextField';
import logo from '../../images/rd-logo.png';
import api from '../../services/api';
import { AuthContext } from './AuthProvider';
import { LoginCredentials } from './types';

const isSubmitting = (form: { getState: () => { submitting: boolean } }) =>
  form.getState().submitting;

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  passwordField: {
    width: '100%',
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const debug = (...msgs: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...msgs);
  }
};

const Login = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (credentials: LoginCredentials) => {
      setLoading(true);
      const response = await api.login(credentials);
      debug('response login', response);
      if (!response.ok) {
        setLoading(false);
        return setErrorMessage('Usuário ou senha inválidos');
      }
      debug('context initialized:', context.initialized);
      if (context.initialized && response.ok) {
        api.setToken(response.data?.access_token as string);
        const manager = await api.getUser(credentials.username);
        if (manager.data && response.data) {
          context.handleUserLogin({
            token: response.data.access_token,
            firstName: manager.data.firstName,
            role: manager.data.role,
          });
        }
        setLoading(false);
      }
    },
    [context.initialized],
  );

  const validate = useCallback((values: LoginCredentials) => {
    return {
      username: values.username?.trim().length ? undefined : 'Obrigatório',
      password: values.password?.trim().length ? undefined : 'Obrigatório',
    };
  }, []);

  const handleRecover = useCallback(() => {
    history.push('/recuperar-senha');
  }, []);

  if (context.initialized && context.state.loggedin) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.background}>
      <Paper style={{ padding: 20, minWidth: 300 }} elevation={4}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={logo} alt="Logo RD" />
          <Spacer y={2} />
        </Grid>
        <Form onSubmit={handleSubmit} validate={validate}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Field fullWidth component={TextField} label="E-mail" name="username" />
                </Grid>
                <Grid item>
                  <Field fullWidth component={PasswordField} label="Senha" name="password" />
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    disabled={isSubmitting(form)}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {!loading ? (
                      'Entrar'
                    ) : (
                      <div className={classes.loading}>
                        <CircularProgress color="inherit" size={24} />
                      </div>
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="button" fullWidth onClick={handleRecover}>
                    Recuperar Senha
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </Paper>
      <Snackbar onClose={() => setErrorMessage('')} message={errorMessage} />
    </div>
  );
};

export default Login;
