import { TableCell } from "@material-ui/core";
import { vi } from "date-fns/locale";
import { formatedValueUSDT } from "../utils/formatValue";

type Props = {
    visible?: boolean;
    value?: number | bigint;
}

export function TableCellUSDT({visible = true, value = 0}: Props) {
    return (
        <>
            {visible && (
              <TableCell>
                {formatedValueUSDT(value)}
              </TableCell>
            )}
        </>
      );
}