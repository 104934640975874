import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import SimpleDialog from './SimpleDialog';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

type Props = {
  onToggle: () => void;
  toggle: boolean;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function OpenToReplication({ onToggle: onToggle, title, message, hoverText, disabled, toggle }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let toggleOption: string;
  if (toggle) {
    toggleOption = `Abrir`
  } else {
    toggleOption = 'Fechar'
  }
  return (
    <>
      <Tooltip title={hoverText || `${toggleOption} janela de replicação.`} aria-label={hoverText || `${toggleOption} janela de replicação.`}>
        <TableCell align="right" padding="checkbox">
          {toggle ? <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <LockOpenOutlinedIcon />
          </IconButton> : <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <LockOutlinedIcon />
          </IconButton>}
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || `Deseja realmente ${toggleOption.toLowerCase()} a janela de replicação do trader?`}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onToggle}
        title={title || `${toggleOption} janela de replicação`}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
