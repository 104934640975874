import { ApiResponse, create } from 'apisauce';
import config from '../config';
import { LoginCredentials, User } from '../modules/login/types';
import { ITraderBinanceCredencialsForm } from '../modules/trader-binance-api-update/types';
import { DataResponseLogin } from './types';

export const apiSauceInstance = create({
  baseURL: config.baseURL,
});

const realApi = {
  setToken: (token: string) => {
    return apiSauceInstance.setHeader('Authorization', `Bearer ${token}`);
  },
  getManagers: () => {
    return apiSauceInstance.get('/manager');
  },
  ping: () => apiSauceInstance.get('/me'),
  getUser: async (username: string): Promise<ApiResponse<User>> => {
    return apiSauceInstance.get(`/manager/${username}`);
  },
  login: async ({
    username,
    password,
  }: LoginCredentials): Promise<ApiResponse<DataResponseLogin, { code: number }>> => {
    return apiSauceInstance.post('/auth/manager/login', {
      username,
      password,
    });
  },
  patchTraderBinanceCredencials: async (
    id: number,
    data: ITraderBinanceCredencialsForm,
  ): Promise<ApiResponse<any>> => {
    return apiSauceInstance.patch(`/professionals/${id}/binance-api-update`, data);
  },
  patchUserBinanceCredencials: async (
    id: number,
    data: ITraderBinanceCredencialsForm,
  ): Promise<ApiResponse<any>> => {
    return apiSauceInstance.patch(`/users/${id}/binance-api-update`, data);
  },
  getProfile: async (): Promise<ApiResponse<any>> => {
    return apiSauceInstance.get(`/auth/profile`);
  },
};

const api = realApi;

export default api;
