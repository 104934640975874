import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { useState } from 'react';
import SimpleDialog from './SimpleDialog';

type Props = {
  onHomologation: () => void;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function HomologationRecord({ onHomologation: onHomologation, title, message, hoverText, disabled }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={hoverText || 'Colocar usuário em período de homologação?'} aria-label={hoverText || 'Colocar usuário em período de homologação?'}>
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <SupervisorAccountIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente colocar usuário em período de homologação??'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onHomologation}
        title={title || 'Colocar usuário em período de homologação'}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
