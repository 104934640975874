import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import { formatDate } from '../../utils/format-date';
import { FormatEndModeIcons } from '../../utils/formatEndModeIcons';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useReplicationLogs } from './hooks/useReplicationLogs';
import { ReplicationCycleDetail, ReplicationCycleInvestorsDetail } from './types';
import { useReplicationCycle } from './hooks/useReplicationCycle';
import { TableCellUSDT } from '../../components/TableCellUSDT';
import { TableCellPercent } from '../../components/TableCellPercent';

interface Props {
  cycle: ReplicationCycleDetail | undefined;
  list: ReplicationCycleInvestorsDetail[];
  loading: boolean;
  totalCount: number;
  onDelete: (parentId: string, id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
  };
}

export const ReplicationLogsTable = ({
  cycle,
  list,
  loading,
  totalCount,
  onDelete,
  authorization,
}: Props) => {
  const location = useLocation();
  const isCycleOnGoing: boolean = cycle?.status === 'ONGOING' ?? false;
  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Investidor" sort="" />
                  <TableCellHead label="Saldo Inicial" sort="" />
                  <TableCellHead visible={isCycleOnGoing} label="Saldo Atual" sort="" />
                  <TableCellHead visible={!isCycleOnGoing} label="Saldo Final" sort="" />
                  <TableCellHead label="Ganho/Perda" sort="" />
                  <TableCellHead label="% Ganho/Perda" sort="" />
                  <TableCellHead visible={isCycleOnGoing} label="Saldo Margem" sort="" />
                  <TableCellHead label="Situação" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replicationLog) => (
                  <TableRow hover key={replicationLog.investor.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/users/${replicationLog.investor.id}`}>
                        {`${replicationLog.investor.semId} - ${replicationLog.investor.firstName}`}
                      </LinkMD>
                    </TableCell>
                    <TableCellUSDT value={replicationLog.start.initialBalance} />
                    <TableCellUSDT
                      visible={isCycleOnGoing}
                      value={replicationLog?.progress?.totalWalletBalance}
                    />
                    <TableCellUSDT
                      visible={!isCycleOnGoing}
                      value={replicationLog?.end?.finalBalance}
                    />
                    <TableCellUSDT value={replicationLog.result.balance} />
                    <TableCellPercent value={replicationLog.result.percentBalance} />
                    <TableCellUSDT
                      visible={isCycleOnGoing}
                      value={replicationLog?.progress?.totalMarginBalance}
                    />
                    <TableCell>
                      {FormatEndModeIcons(
                        replicationLog.end.mode,
                        formatDate(replicationLog.end.date),
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={location.pathname} authorization={authorization} />
    </>
  );
};

export const ReplicationLogs = () => {
  const [state, actions] = useReplicationLogs();
  const [replicationCycleState] = useReplicationCycle();
  const params = useParams<{ id: string; childId: string }>();
  const cycle =
    replicationCycleState.tag === 'with-data' ? replicationCycleState.entity : undefined;

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const urlParam = new URLSearchParams(search);
      actions.fetchPage(parse(urlParam.toString()), Number(params.id));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return (
        <ReplicationLogsTable
          onDelete={() => {}}
          totalCount={0}
          cycle={cycle}
          list={[]}
          loading={true}
          authorization={{ canCreate: false, canDelete: false }}
        />
      );
    case 'with-data':
      return (
        <ReplicationLogsTable
          cycle={cycle}
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onDelete={() => {}}
          authorization={{ canCreate: false, canDelete: false }}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data} />;
    default:
      return <HandleErrorPage state={null} />;
  }
};
