import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { IFaqForm, FaqDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const faqRepository = makeRepository<EdgesPage<FaqDetail>, IFaqForm, FaqDetail, number>(
  { resource: 'faqs', mapQueryParams },
  apiSauceInstance,
);
