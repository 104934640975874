import { SortDirection } from './sort-direction';

export class Sort {
  constructor(public field: string, public direction: SortDirection) {}

  compare(left: any, right: any) {
    let leftValue = left;
    let rightValue = right;

    if (this.direction == SortDirection.DESC) {
      leftValue = right;
      rightValue = left;
    }

    if (typeof leftValue === 'number' && typeof leftValue === 'number') {
      return leftValue - rightValue;
    } else if (typeof leftValue === 'string' && typeof rightValue === 'string') {
      return leftValue.localeCompare(rightValue);
    } else {
      return typeof leftValue.localeCompare(rightValue) === 'number' ? -1 : 1;
    }
  }
}
