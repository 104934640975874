import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { useCreditAccountOperation } from '../hooks/creditAccountOperation';
import { CreditAccountOperationForm } from './CreditAccountOperationForm';

export function CreditAccountOperationMasterDetail() {
  const [creditAccountOperationState, creditAccountOperationActions] = useCreditAccountOperation();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      creditAccountOperationState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(creditAccountOperationActions.fetch, creditAccountOperationActions.reset);
  return handleStateErrorsToRender(creditAccountOperationState, <CreditAccountOperationForm />);
}
