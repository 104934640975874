import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import NoResultsTable from '../../components/NoResultsTable';
import { OpenToReplication } from '../../components/OpenToReplicationRecord';
import Spacer from '../../components/Spacer';
import { StartReplicationRecord } from '../../components/StartReplicationRecord';
import { StopReplicationRecord } from '../../components/StopReplicationRecord';
import { TableCellHead } from '../../components/TableCellHead';
import TextInput from '../../components/TextInput';
import { apiSauceInstance } from '../../services/api';
import { formatDate } from '../../utils/format-date';
import { formatPercent } from '../../utils/formatPercent';
import { formatStatusReplicationCycle } from '../../utils/formatStatusReplicationCycle';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useReplicationCycles } from './hooks/useReplicationCycles';
import { ReplicationCycleDetail } from './types';

interface Props {
  list: ReplicationCycleDetail[];
  loading: boolean;
  totalCount: number;
  onToggleOpenClose: (id: ReplicationCycleDetail) => void;
  onToggleStart: (id: ReplicationCycleDetail) => void;
  onToggleStop: (id: ReplicationCycleDetail) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type ReplicationCycleQueryFilter = {
  userId: string;
};

const ReplicationCycleFilter = () => {
  const { clear, push } = useQueryParams<ReplicationCycleQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: ReplicationCycleQueryFilter & { page: number }) => {
    push({
      ...values,
    });
  };

  return (
    <FilterWrap
      initialValues={{
        userId: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput name="lastName" label="Trader" />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            name="isHomologationCycle"
            label="Tipos de ciclo"
            options={[
              { value: '', label: 'Todos' },
              { value: 'true', label: 'Ciclo de Homologação' },
              { value: 'false', label: 'Ciclo de Produção' },
            ]}
          />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const ReplicationCycleTable = ({
  list,
  loading,
  totalCount,
  onToggleOpenClose,
  onToggleStart,
  onToggleStop,
}: Props) => {
  return (
    <>
      <ReplicationCycleFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Identificador do Ciclo" sort="" />
                  <TableCellHead label="Trader" sort="" />
                  <TableCellHead label="Data Inicial Prev." sort="" />
                  <TableCellHead label="Data Final Prev." sort="" />
                  <TableCellHead label="Data Inicial" sort="" />
                  <TableCellHead label="Data Final" sort="" />
                  <TableCellHead label="% Resultado" sort="" />
                  <TableCellHead label="Qtd. Dias do Ciclo" sort="" />
                  <TableCellHead label="Janela de Replicação" sort="" />
                  <TableCellHead label="Status" sort="" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replicationCycle) => (
                  <TableRow
                    key={replicationCycle.id}
                    selected={replicationCycle.status === 'ONGOING'}
                  >
                    <TableCell>
                      <LinkMD component={Link} to={`/replication-cycle/${replicationCycle.id}`}>
                        {replicationCycle.semId}
                      </LinkMD>
                    </TableCell>
                    <TableCell>
                      <LinkMD component={Link} to={`/professionals/${replicationCycle.traderId}`}>
                        {replicationCycle.traderFK.lastName}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{formatDate(replicationCycle.expectedStartDate)}</TableCell>
                    <TableCell>{formatDate(replicationCycle.expectedEndDate)}</TableCell>
                    <TableCell>{formatDate(replicationCycle.actualStartDate)}</TableCell>
                    <TableCell>{formatDate(replicationCycle.actualEndDate)}</TableCell>
                    <TableCell>
                      {formatPercent(replicationCycle.allInvestorsPercentRentability)}
                    </TableCell>
                    <TableCell>{replicationCycle.numberOfDays}</TableCell>
                    <TableCell>
                      {replicationCycle.openToReplication ? 'Aberta' : 'Fechada'}
                    </TableCell>
                    <TableCell>{formatStatusReplicationCycle(replicationCycle.status)}</TableCell>{' '}
                    <OpenToReplication
                      onToggle={() => onToggleOpenClose(replicationCycle)}
                      toggle={!replicationCycle.openToReplication}
                      disabled={replicationCycle.status != 'WAITING'}
                    />
                    <StopReplicationRecord
                      onToggle={() => onToggleStop(replicationCycle)}
                      disabled={replicationCycle.status === 'FINISHED'}
                    />
                    <StartReplicationRecord
                      onToggle={() => onToggleStart(replicationCycle)}
                      disabled={replicationCycle.status != 'WAITING'}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const ReplicationCycleList = (props: any) => {
  const [state, actions] = useReplicationCycles();
  const [, snackbarActions] = useSnackbar();

  async function handleToggleOpenClose(cycle: ReplicationCycleDetail) {
    const path = cycle.openToReplication ? 'close-replication' : 'open-replication';
    const res: ApiResponse<any> = await apiSauceInstance.post(
      `replication-cycle/${cycle.id}/${path}`,
    );
    if (res.ok) {
      snackbarActions.setMessage('Janela de replicação alterada com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleToggleStart(cycle: ReplicationCycleDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.post(
      `replication-cycle/${cycle.id}/start-cycle`,
    );
    if (res.ok) {
      snackbarActions.setMessage('Ciclo iniciado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleToggleStop(cycle: ReplicationCycleDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.post(
      `replication-cycle/${cycle.id}/stop-cycle`,
    );
    if (res.ok) {
      snackbarActions.setMessage('Ciclo finalizado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return (
        <ReplicationCycleTable
          totalCount={0}
          onToggleStop={() => {}}
          onToggleStart={() => {}}
          onToggleOpenClose={() => {}}
          list={[]}
          loading={true}
        />
      );
    case 'with-data':
      return (
        <ReplicationCycleTable
          list={state.list}
          onToggleStart={handleToggleStart}
          onToggleStop={handleToggleStop}
          onToggleOpenClose={handleToggleOpenClose}
          totalCount={state.totalCount}
          loading={state.loading}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default ReplicationCycleList;
