import { useQueryParams } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useVideos } from './hooks/useVideos';
import { VideoDetail, VideoType, videoTypeOptions } from './types';

interface Props {
  list: VideoDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type VideoQueryFilter = {
  type: VideoType | undefined;
};

const VideoFilter = () => {
  const { clear, push } = useQueryParams<VideoQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: VideoQueryFilter & { page: number }) => {
    push(values);
  };

  return (
    <FilterWrap
      initialValues={{
        type: undefined,
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput name="type" label="Tipo" options={videoTypeOptions} />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const VideoTable = ({ list, loading, totalCount, authorization }: Props) => {
  return (
    <>
      <VideoFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="URL do Vídeo" sort="video" />
                  <TableCellHead label="Tipo" sort="type" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((video) => (
                  <TableRow hover key={video.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/videos/${video.id}`}>
                        {video.url}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{video.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="videos" authorization={authorization} />
    </>
  );
};

const VideoList = (props: any) => {
  const [state, actions] = useVideos();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <VideoTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return <VideoTable list={state.list} totalCount={state.totalCount} loading={state.loading} />;
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default VideoList;
