export const formatStatusReplicationCycle = (value: string) => {
  switch (value) {
    case 'WAITING':
      return 'Em espera';
    case 'ONGOING':
      return 'Em curso';
    case 'FINISHED':
      return 'Finalizado';
    default:
      break;
  }
};
