import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppWrap from '../../components/AppWrap';
import { Item } from '../../components/AppWrap/MenuItems';
import { RouteByMenu } from '../../components/RouterByMenu';
import logo from '../../images/logo.svg';
import menuItems from './MenuItems';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '100%',
    filter: 'brightness(100)',
  },
  logoOpen: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HomeNavigation = (): JSX.Element => {
  const matches = useMediaQuery('(max-width:900px)');
  const [userMenuItems, setUserMenuItems] = useState<Item[]>([]);
  const [open, setOpen] = useState(localStorage.toggleDrawer === '1');
  const classes = useStyles();
  const onToggleDrawer = (status: string) => {
    setOpen(status === 'open');
  };

  useEffect(() => {
    setUserMenuItems(menuItems);
  }, [setUserMenuItems]);

  useEffect(() => {
    if (matches === true) {
      setOpen(false);
    }
  }, [matches]);

  return (
    <AppWrap
      logo={
        <div className={open ? classes.logoOpen : classes.logoClose}>
          {open ? (
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item xs={3}>
                <img src={logo} alt="Rendimentos Digitais" className={classes.logo} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Rendimentos Digitais</Typography>
              </Grid>
            </Grid>
          ) : (
            <img src={logo} alt="Rendimentos Digitais" className={classes.logo} />
          )}
        </div>
      }
      menuItems={userMenuItems}
      isPositionButtonMenuDrawer={false}
      onToggleDrawer={onToggleDrawer}
      classes={null}
    >
      <div style={{ height: '100%' }}>
        <Switch>
          <RouteByMenu menuItems={userMenuItems}></RouteByMenu>
        </Switch>
      </div>
    </AppWrap>
  );
};

export default HomeNavigation;
