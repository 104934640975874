import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { useSupport, useUser } from '../hooks/useUser';
import { UserTabs } from './UserTabs';

export function UserMasterDetail() {
  const [userState, userActions] = useUser();
  const [supportState] = useSupport();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      userState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
    mapReplaceFragment(
      supportState,
      (o: any) => o.entity.id,
      (o: any) => o.entity.id,
    ),
  ]);
  useFetchAndReset(userActions.fetch, userActions.reset);
  return handleStateErrorsToRender(userState, <UserTabs />);
}
