import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import SimpleDialog from './SimpleDialog';

type Props = {
  onDeny: () => void;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function DenyRecord({ onDeny: onDeny, title, message, hoverText, disabled }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={hoverText || 'Reprovar usuário'} aria-label={hoverText || 'Reprovar usuário'}>
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <CloseIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente reprovar esse usuário?'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onDeny}
        title={title || 'Reprovar este Usuário'}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
