import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Paper } from '../../../components';
import DateInput from '../../../components/Formik/Forms/DateInput';
import TextInput from '../../../components/TextInput';
import { formatPercent } from '../../../utils/formatPercent';
import { formatedValueUSDT } from '../../../utils/formatValue';
import { replicationCycleRepository } from '../hooks/replicationCycleRepository';
import { useReplicationCycle } from '../hooks/useReplicationCycle';
import { ReplicationCycleDetail } from '../types';

export const ReplicationCycleForm = () => {
  const history = useHistory();
  const [replicationCycleState] = useReplicationCycle();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<ReplicationCycleDetail>(replicationCycleRepository.save);

  const handleSubmit = async (replicationCycle: any) => {
    setLoading(true);
    await save({
      ...replicationCycle,
    });
    setLoading(false);
  };

  const formatData = (values: ReplicationCycleDetail) => {
    return {
      ...values,
      lastName: values.traderFK.lastName,
      isHomologationCycle: values.isHomologationCycle ? 'Sim' : 'Não',
      amoutProfitAllInvestorsCycle: formatedValueUSDT(Number(values.amoutProfitAllInvestorsCycle)),
      initialBalanceAllInvestors: formatedValueUSDT(Number(values.initialBalanceAllInvestors)),
      finalBalanceAllInvestors: formatedValueUSDT(Number(values.finalBalanceAllInvestors)),
      allInvestorsPercentRentability: formatPercent(values.allInvestorsPercentRentability),
      traderPercentRentability: formatPercent(Number(values.traderPercentRentability)),
      goalProfit: formatPercent(Number(values.goalProfit)),
      isProfitableCycle:
        values.isProfitableCycle == undefined
          ? 'Ciclo não finalizado'
          : values.isProfitableCycle
          ? 'Sim'
          : 'Não',
      openToReplication: values.openToReplication ? 'Aberta' : 'Fechada',
    };
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        replicationCycleState.tag === 'with-data'
          ? formatData(replicationCycleState.entity)
          : ({} as ReplicationCycleDetail)
      }
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextInput label="Idenficador" disabled debounce name="semId" />
              </Grid>
              <Grid item xs={2}>
                <TextInput label="Ano" disabled debounce name="year" />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  label="Ciclo de Homologação?"
                  disabled
                  debounce
                  name="isHomologationCycle"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput label="Ciclo Lucrativo?" disabled debounce name="isProfitableCycle" />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Janela de Replicação"
                  disabled
                  debounce
                  name="openToReplication"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Trader" disabled debounce name="lastName" />
              </Grid>
              <Grid item xs={3}>
                <TextInput label="Meta (%)" disabled debounce name="cycleTraderGoal" />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Percentagem de Rentabilidade"
                  disabled
                  debounce
                  name="traderPercentRentability"
                />
              </Grid>
              <Grid item xs={3}>
                <DateInput label="Data Inicial Prev." name="expectedStartDate" />
              </Grid>
              <Grid item xs={3}>
                <DateInput label="Data Final Prev." name="expectedEndDate" />
              </Grid>
              <Grid item xs={3}>
                <DateInput label="Data Inicial" name="actualStartDate" />
              </Grid>
              <Grid item xs={3}>
                <DateInput label="Data Final" name="actualEndDate" />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Valor Inicial Carteira"
                  disabled
                  debounce
                  name="initialBalanceAllInvestors"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Valor Final Carteira"
                  disabled
                  debounce
                  name="finalBalanceAllInvestors"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Valor de Ganho"
                  disabled
                  debounce
                  name="amoutProfitAllInvestorsCycle"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="% Rentabilidade"
                  disabled
                  debounce
                  name="allInvestorsPercentRentability"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Numero de investidores"
                  disabled
                  debounce
                  name="numberOfInvestors"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput label="Quantidades de Dias" disabled debounce name="numberOfDays" />
              </Grid>
              <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
                <DialogActions>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    color="primary"
                    onClick={handleGoBack}
                  >
                    Voltar
                  </Button>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
