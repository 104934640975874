import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from '@elentari/core/hooks/useSnackbar';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withScollBar } from './App.styles';
import Snackbar from './components/Snackbar';
import './font.css';
import HomeNavigation from './modules/home/HomeNavigation';
import Invite from './modules/invite-investor/InviteInvestor';
import { AuthProvider, Login, PrivateRoute } from './modules/login';
import theme from './Theme';

const App = () => {
  const [snackbarState, snackbaractions] = useSnackbar();

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/invite" component={Invite} />
              <PrivateRoute loginPath="/login" path="/" render={() => <HomeNavigation />} />
            </Switch>
          </Router>
          <Snackbar
            message={snackbarState.message}
            onClose={() => snackbaractions.setMessage('')}
            classes={{}}
          />
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default withScollBar(App);
