import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { useReplication } from '../hooks/useReplication';
import { ReplicationForm } from './ReplicationForm';

export function ReplicationMasterDetail() {
  const [replicationState, replicationActions] = useReplication();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      replicationState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  return handleStateErrorsToRender(replicationState, <ReplicationForm />);
}
