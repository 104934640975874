import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { CircularProgress, DialogActions, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import DateInput from '../../components/DateInput';
import EmailInput from '../../components/Formik/Forms/Inputs/EmailInput';
import MaskInput from '../../components/Formik/Forms/MaskInput';
import TextInput from '../../components/Formik/Forms/TextInput';
import Spacer from '../../components/Spacer';
import yupValidation from '../../utils/yupValidation';
import { MessagesYup } from '../messages';
import { userRepository } from '../user/hooks/userRepository';
import { IUserForm } from '../user/types';

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    padding: '20px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const Invite = () => {
  const classes = useStyles();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const refId = parse(params.toString()).ref?.toString();
  const [state, setState] = useState('');

  useEffect(() => {
    if (refId == '' || !refId) {
      setState('error');
    } else {
      setState('success');
    }
  }, [refId]);

  const { save } = useEntitySaver<IUserForm>(userRepository.save);

  const handleSubmit = async (user: IUserForm) => {
    const { birthDate, cpf, email, firstName, lastName, phoneRegion, phone, limit, test } = user;
    await save({
      referrerCode: refId,
      birthDate,
      email,
      firstName,
      lastName,
      phoneRegion,
      phone,
      limit,
      test,
      cpf: cpf.replace(/[^\d]+/g, ''),
    });
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    phoneRegion: Yup.string().trim().required('Obrigatório'),
    phone: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    lastName: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    email: Yup.string().email().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    cpf: Yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  });

  const initialValues = {
    email: '',
    firstName: '',
    phoneRegion: '',
    phone: '',
    lastName: '',
    cpf: '',
    birthDate: new Date(),
    test: false,
    limit: false,
  };

  return (
    <div className={classes.background}>
      <Formik
        enableReinitialize
        validate={yupValidation(schema)}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ isSubmitting }) => (
          <Form>
            <Paper elevation={3} style={{ padding: '10px' }}>
              <Typography variant="h5">Códiogo do indicador: {refId}</Typography>
              <Spacer y={4} />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextInput name="firstName" label="Primeiro Nome" />
                </Grid>
                <Grid item xs={4}>
                  <TextInput name="lastName" label="Ultimo Nome" />
                </Grid>
                <Grid item xs={1}>
                  <MaskInput name="phoneRegion" label="Região" mask="+99" />
                </Grid>
                <Grid item xs={3}>
                  <MaskInput name="phone" label="Telefone Celular" mask="(99) 99999-9999" />
                </Grid>
                <Grid item xs={4}>
                  <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
                </Grid>
                <Grid item xs={4}>
                  <DateInput label="Data de Nascimento" name="birthDate" />
                </Grid>
                <Grid item xs={4}>
                  <EmailInput name="email" label="Email" />
                </Grid>
              </Grid>
              <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
                <DialogActions>
                  <Button
                    fullWidth
                    disabled={isSubmitting || state == 'error'}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                  </Button>
                </DialogActions>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Invite;
