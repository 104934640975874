import { withStyles } from '@material-ui/core';
import theme from './Theme';

const scrollbarStyles = () => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 16,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '*::-webkit-scrollbar:horizontal': {
      height: 5,
    },
  },
});

export const withScollBar = withStyles(scrollbarStyles);
