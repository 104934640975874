import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { replicationRepository } from '../hooks/replicationRepository';
import { useReplication } from '../hooks/useReplication';
import { IReplicationForm, Replication } from '../types';

const schema = yup.object().shape({
  investorId: yup.object().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  traderId: yup.object().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const ReplicationForm = () => {
  const history = useHistory();
  const [replicationState] = useReplication();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<IReplicationForm>(replicationRepository.save);

  const handleSubmit = async (replication: any) => {
    setLoading(true);
    const res = await save({
      investorId: replication.investorId.value,
      traderId: replication.traderId.value,
    });
    if (res.ok) {
      handleGoBack();
    }
    setLoading(false);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues = {
    investorId: '',
    traderId: '',
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AsyncSearchInput
                  name="investorId"
                  label="Investidor"
                  url="/users"
                  labelField={['firstName', 'lastName']}
                />
              </Grid>
              <Grid item xs={6}>
                <AsyncSearchInput
                  name="traderId"
                  label="Trader"
                  url="/professionals"
                  labelField={['firstName', 'lastName']}
                />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
