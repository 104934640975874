/* eslint-disable eqeqeq */
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper } from '../components';
import logo from '../images/logo-login.png';

type Data = {
  state: {
    statusCode: string;
    timestamp: string;
    path: string;
    message?: string;
  };
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    width: '100%',
    maxWidth: 980,
  },
  status: {
    fontWeight: 'bold',
    lineHeight: 2,
    letterSpacing: 15,
  },
  button: {
    marginTop: 100,
    width: '30%',
  },
  image: {
    position: 'absolute',
    top: 200,
    right: 200,
    width: 320,
    opacity: 0.1,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
});

export const HandleErrorPage = (state: any) => {
  const [data, setData] = useState(state as Data);
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {data.state && data.state.statusCode == '401' ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" size={80} />
        </div>
      ) : (
        <Paper>
          <div className={classes.page}>
            <div className={classes.root}>
              <Typography className={classes.status} variant="h2">
                {data.state.statusCode}
              </Typography>

              <br />

              <Typography>
                <strong>
                  Por favor, procurar a equipe técnica.
                  <br /> ocorreu um erro:{' '}
                </strong>
                {data.state.path}
              </Typography>
              <br />
              <Typography>
                <strong>Horário:</strong> {data.state.timestamp}
              </Typography>
              <br />
              {data.state.message && (
                <Typography>
                  <strong>Mensagem:</strong> {data.state.message}
                </Typography>
              )}

              <Button
                className={classes.button}
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => history.push('/')}
              >
                Voltar para tela inicial
              </Button>
            </div>
            <img className={classes.image} src={logo} alt="Rendimentos Digitais" />
          </div>
        </Paper>
      )}
    </>
  );
};
