import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import SelectInput from '../../../components/Formik/Forms/SelectInput';
import TextInput from '../../../components/TextInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { addressRepository, getAllRdaddressesTypes } from '../hooks/addressRepository';
import { useAddress } from '../hooks/useAddress';
import { IAddressForm } from '../types';

const schema = yup.object().shape({
  description: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  address: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  network: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  type: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  email: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  apiKey: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  secretKey: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const AddressForm = () => {
  const history = useHistory();
  const [addressState] = useAddress();
  const [, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<any>([]);

  useEffect(() => {
    getAllTypes();
  }, []);

  const getAllTypes = async () => {
    const res = await getAllRdaddressesTypes();
    if (res?.ok) {
      setType(
        res.info.edges.map((item: any) => {
          return { label: item.node.type, value: item.node.type };
        }),
      );
    }
  };

  const { save } = useEntitySaver<IAddressForm>(addressRepository.save);

  const handleSubmit = async (address: any) => {
    setLoading(true);
    await save(address);
    setLoading(false);
  };

  const formatData = (values: IAddressForm) => {
    return values;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues = {
    description: '',
    address: '',
    network: '',
    type: '',
    email: '',
    apiKey: '',
    secretKey: '',
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        addressState.tag === 'with-data' ? formatData(addressState.entity) : initialValues
      }
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="description" label="Descrição" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="address" label="Endereço" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="network" label="Rede" />
              </Grid>
              <Grid item xs={6}>
                <SelectInput options={type} name="type" label="Tipo do Endereço" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="email" label="E-mail" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="apiKey" label="Api Key" />
              </Grid>
              <Grid item xs={6}>
                <TextInput name="secretKey" label="Secret Key" />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
