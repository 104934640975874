export interface Trader {
  id: number;
  email: string;
  phone: string;
  phoneRegion: string;
  password: string;
  firstName: string;
  lastName: string;
  cpf: string;
  birthDate: Date;
  walletAddress?: string;
  taxRate: number;
  profilePhoto: string;
  description: string;
  walletVolume: number;
  semId: string;
  status: string;
  apiKey: string;
  secretKey: string;
  tokenCreateTime: Date;
  openToReplication: boolean;
  expSince: Date;
  leverage: number;
  operationRate: number;
  cycleAvgTime: number;
  risk: string;
  goalProfit: number;
}

export const status = [
  {
    label: 'Pendente',
    value: 'PENDING',
  },
  {
    label: 'Reprovado',
    value: 'DENIED',
  },
  {
    label: 'Aprovado',
    value: 'APPROVED',
  },
  {
    label: 'Desligado',
    value: 'OFF',
  },
];

export interface ITraderForm {
  id?: number;
  email: string;
  phone: string;
  phoneRegion: string;
  firstName: string;
  lastName: string;
  cpf: string;
  birthDate: Date;
  walletAddress?: string;
  taxRate?: number;
  profilePhoto?: string;
  description?: string;
  apiKey?: string;
  secretKey?: string;
  expSince: Date;
  leverage?: number;
  operationRate: number;
  cycleAvgTime: number;
  risk: string;
  goalProfit: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TraderDetail extends Trader {}

export interface TraderReplicationCycleDetail {
  id?: number;
  createdAt: Date;
  semId: string;
  isProfitableCycle: boolean;
  traderPercentRentability: string;
  amoutProfitTraderCycle: number;
  traderFK: TraderDetail;
  initialBalance: number;
  finalBalance: number;
  numberOfDays: number;
  minichart: Array<{ uv: number }>;
}

export interface TraderReplicationPositionDetail {
  id?: number;
  traderSnapshotTraderId: number;
  createdAt: Date;
  symbol: string;
  positionAmt: string;
  entryPrice: bigint;
  markPrice: bigint;
  unRealizedProfit: bigint;
  leverage: number;
}

export interface TraderReplicationMetricsDetail {
  id?: number;
  countTotalCycles: number;
  countProfitableCycle: number;
  traderPercentRentability: string;
  amoutProfitTraderCycle: number;
  initialBalance: number;
  finalBalance: number;
  numberOfDaysAvg: number;
}
