export const formatPercent = (value: number) => {
  if (value === undefined) {
    return '';
  }
  return Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
    .format(value)
    .replace('.', ',')
    .concat('%');
};
