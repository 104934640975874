import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';

interface SelectInputProps {
  label: string;
  name: string;
  disabled?: boolean;
}

function SimpleCheckbox(props: SelectInputProps): JSX.Element {
  const [field] = useField(props);
  const { label, name, disabled } = props;

  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} color="primary" {...field} {...props} />}
      label={label}
      disabled={disabled}
    />
  );
}

export default SimpleCheckbox;
