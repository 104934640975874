import { CrudRoute, CrudTabs, useTabsNavigation } from '@elentari/components/crud/CrudTabs';
import { ReplicationBound } from '../ReplicationBoundList';
import { ReplicationLogs } from '../ReplicationLogs';
import { ReplicationPositions } from '../ReplicationPositions';
import { ReplicationCycleForm } from './ReplicationCycleForm';
import { useReplicationCycle } from '../hooks/useReplicationCycle';

export const ReplicationCycleTabs = () => {
  const [replicationCycleState] = useReplicationCycle();
  const isNotCycleFinished =
    replicationCycleState.tag === 'with-data' && replicationCycleState.entity.status !== 'FINISHED';

  const buildTabs = () => {
    const tabs = [
      {
        value: '',
        label: 'Ciclo de Replicação',
      },
      {
        value: 'replication-logs',
        label: 'Investidores',
      },
    ];

    if (isNotCycleFinished) {
      tabs.push(
        {
          value: 'replication-bound',
          label: 'Replicações Pendentes',
        },
        {
          value: 'positions',
          label: 'Posições',
        },
      );
    }

    return tabs;
  };

  const hook = useTabsNavigation({
    mainPath: 'replication-cycle',
    tabs: buildTabs(),
  });

  return (
    <CrudTabs {...hook} disableBadge withPaper withDivider>
      <CrudRoute name="" isForm render={() => <ReplicationCycleForm />} />
      <CrudRoute name="replication-logs" render={() => <ReplicationLogs />} />
      {isNotCycleFinished && (
        <CrudRoute name="replication-bound" render={() => <ReplicationBound />} />
      )}
      {isNotCycleFinished && <CrudRoute name="positions" render={() => <ReplicationPositions />} />}
    </CrudTabs>
  );
};
