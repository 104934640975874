import { useQueryParams } from '@elentari/core';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { formatDate } from '../../utils/format-date';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useTerms } from './hooks/useTerms';
import { TermDetail, TermType } from './types';

interface Props {
  list: TermDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type TermQueryFilter = {
  isActive: boolean | undefined;
};

const TermFilter = () => {
  const { clear, push } = useQueryParams<TermQueryFilter>();

  const type = [
    {
      label: 'Sim',
      value: 1,
    },
    {
      label: 'Não',
      value: 0,
    },
  ];

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: TermQueryFilter & { page: number }) => {
    push(values);
  };

  return (
    <FilterWrap
      initialValues={{
        isActive: undefined,
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput
            name="type"
            label="Tipo"
            options={[
              { label: 'Termo Geral', value: TermType.GENERAL },
              { label: 'Termo de Risco', value: TermType.RISK },
              { label: 'Termo de Cancelamento', value: TermType.ACCOUNT_CANCEL },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput options={type} name="isActive" label="Ativo" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const TermTable = ({ list, loading, totalCount, authorization }: Props) => {
  return (
    <>
      <TermFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Termo" sort="term" />
                  <TableCellHead label="Tipo" sort="type" />
                  <TableCellHead label="Criado Em" sort="createdAt" />
                  <TableCellHead label="Ativo" sort="isActive" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((term) => (
                  <TableRow hover key={term.id}>
                    <TableCell>
                      {/* Link externo */}
                      {/* <LinkMD component={Link} to={`${term.term}`}>
                        {term.id}
                      </LinkMD> */}
                      {term.term}
                    </TableCell>
                    <TableCell>{term.type}</TableCell>
                    <TableCell>{formatDate(term.createdAt)}</TableCell>
                    <TableCell>{term.isActive ? 'Sim' : 'Não'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="terms" authorization={authorization} />
    </>
  );
};

const TermList = (props: any) => {
  const [state, actions] = useTerms();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <TermTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return <TermTable list={state.list} totalCount={state.totalCount} loading={state.loading} />;
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TermList;
