import { Link, useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMD from '@material-ui/core/Link';
import useAppBar from '../../hooks/useAppBar';
import { useEffect } from 'react';

export default function SimpleBreadcrumb() {
  const location = useLocation();
  const [state, actions] = useAppBar();

  function formatPath(path: string) {
    if (path.length > 20) {
      path = path.substr(0, 20) + '...';
    }

    return path;
  }

  useEffect(() => {
    actions.setPath(location.pathname);
  }, [location.pathname]) // eslint-disable-line

  if (!state.lastPath) {
    return null;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {state.linksPaths?.map((item: { path: string; label: string }) => (
        <LinkMD component={Link} color="inherit" key={item.path} to={item.path}>
          {formatPath(item.label)}
        </LinkMD>
      ))}
      <Typography key={state.lastPath} color="textPrimary">
        {formatPath(state.lastPath)}
      </Typography>
    </Breadcrumbs>
  );
}
