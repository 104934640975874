import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Paper } from '../../../components';
import FileUploadInput from '../../../components/FileUploadInput';
import SelectInput from '../../../components/Formik/Forms/SelectInput';
import { saveUpload } from '../../user/hooks/userRepository';
import { termRepository } from '../hooks/termRepository';
import { useTerm } from '../hooks/useTerm';
import { Term, TermType } from '../types';

export const TermForm = () => {
  const history = useHistory();
  const [termState] = useTerm();
  const [, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const { save } = useEntitySaver<Term>(termRepository.save);

  const handleSubmit = async (term: any) => {
    setLoading(true);
    let fileName;
    if (file) {
      const response = await saveUpload({ file });
      if (response && response.ok) {
        fileName = response.info.Location;
      }
    }
    await save({ ...term, term: fileName });
    setLoading(false);
  };

  const formatData = (values: Term) => {
    return values;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues: Partial<Term> = {
    term: undefined,
    type: TermType.GENERAL,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={termState.tag === 'with-data' ? formatData(termState.entity) : initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FileUploadInput name="term" label="Upload dos Termos de Uso" setFile={setFile} />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  name="type"
                  label="Tipo"
                  options={[
                    { label: 'Termo Geral', value: TermType.GENERAL },
                    { label: 'Termo de Risco', value: TermType.RISK },
                    { label: 'Termo de Cancelamento', value: TermType.ACCOUNT_CANCEL },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
