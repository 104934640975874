import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { useReplicationCycle } from '../hooks/useReplicationCycle';
import { ReplicationCycleTabs } from './ReplicationCycleTabs';

export function ReplicationCycleMasterDetail() {
  const [replicationCycleState, replicationCycleActions] = useReplicationCycle();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      replicationCycleState,
      (u: any) => u.entity.semId,
      (u: any) => u.entity.semId,
    ),
  ]);
  useFetchAndReset(replicationCycleActions.fetch, replicationCycleActions.reset);
  return handleStateErrorsToRender(replicationCycleState, <ReplicationCycleTabs />);
}
