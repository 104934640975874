import { CrudRoute, CrudTabs, useTabsNavigation } from '@elentari/components/crud/CrudTabs';
import TraderReplicationCycleList from '../TraderReplicationCycleList';
import { TraderForm } from './TraderForm';
import { TraderReplicationMetrics } from '../TraderReplicationCycleMetrics';
import TraderPositionsList from '../TraderPositionsList';

export const TraderTabs = () => {
  const hook = useTabsNavigation({
    mainPath: 'professionals',
    tabs: [
      {
        value: '',
        label: 'Profissional',
      },
      { value: 'replication-cycle', label: 'Ciclos Realizados' },
      { value: 'metrics', label: 'Indicadores' },
      { value: 'positions', label: 'Posições' },
    ],
  });
  return (
    <CrudTabs {...hook} disableBadge withPaper withDivider>
      <CrudRoute name="" isForm render={() => <TraderForm />} />
      <CrudRoute name="replication-cycle" render={() => <TraderReplicationCycleList />} />
      <CrudRoute name="positions" render={() => <TraderPositionsList />} />
      <CrudRoute name="metrics" render={() => <TraderReplicationMetrics />} />
    </CrudTabs>
  );
};
