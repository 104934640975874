import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { TraderBinanceCredencialsForm } from './TraderBinanceCredencialsForm';
import { useTraderBinanceCredencial } from '../hooks/useTraderBinanceCredencial';

export function TraderBinanceCredencialsMasterDetail() {
  const [traderBinanceCredencialState, traderBinanceCredencialAction] =
    useTraderBinanceCredencial();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      traderBinanceCredencialState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(traderBinanceCredencialAction.fetch, traderBinanceCredencialAction.reset);
  return handleStateErrorsToRender(traderBinanceCredencialState, <TraderBinanceCredencialsForm />);
}
