import { useHistory } from 'react-router-dom';
import { usePagination } from '@material-ui/lab/Pagination';
import { ButtonBase, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { grey } from '@material-ui/core/colors';
import { ReactEventHandler, useState } from 'react';

interface PageButtonProps {
  page: number;
  selected: boolean;
  onClick: ReactEventHandler<Element>;
}

interface CustomPaginationProps {
  count: number;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const PageButton = ({ page, ...rest }: PageButtonProps) => {
  return (
    <ButtonBase
      style={{
        height: 32,
        minWidth: 32,
        padding: '0 6px',
        borderRadius: 16,
        fontFamily: 'Gilroy, Roboto',
        fontSize: 16,
        margin: '0 4px',
        backgroundColor: rest.selected ? grey[200] : 'transparent',
      }}
      {...rest}
    >
      {page}
    </ButtonBase>
  );
};

const CustomPagination = ({ count = 0 }: CustomPaginationProps) => {
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const rowsPerPage = Number(params.get('rows')) || 10;
  const pageCount = Math.ceil(count / rowsPerPage);
  const page = Number(params.get('page')) || 1;
  const { items } = usePagination({
    count: pageCount,
    page,
    onChange: handleChange,
  });
  const navigationButtons = {
    next: <NavigateNextIcon />,
    previous: <NavigateBeforeIcon />,
    first: <FirstPageIcon />,
    last: <LastPageIcon />,
  };
  const tooltips = {
    next: 'Próxima',
    previous: 'Anterior',
    first: 'Início',
    last: 'Fim',
  };

  function handleChange(event: any, page: number) {
    const { location } = history;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', String(page));
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }

  return (
    <div className={classes.root}>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '…';
        } else if (type === 'page') {
          children = <PageButton selected={selected} {...item} page={page} />;
        } else {
          if (!item.disabled) {
            children = (
              <Tooltip title={tooltips[type]}>
                <IconButton size="small" {...item}>
                  {navigationButtons[type]}
                </IconButton>
              </Tooltip>
            );
          } else {
            children = (
              <IconButton size="small" {...item}>
                {navigationButtons[type]}
              </IconButton>
            );
          }
        }

        return <span key={index}>{children}</span>;
      })}
    </div>
  );
};

export default CustomPagination;
