import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import { parse } from 'qs';
import { useEffect } from 'react';
import { ApproveRecord, DenyRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import TextInput from '../../components/TextInput';
import { apiSauceInstance } from '../../services/api';
import { formatDate } from '../../utils/format-date';
import { formatStatusUser } from '../../utils/formatStatusUser';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useAccountsCancel } from './hooks/useAccountsCancel';
import { AccountCancelDetail } from './types';

interface Props {
  list: AccountCancelDetail[];
  loading: boolean;
  totalCount: number;
  onDeny: (id: string) => void;
  onApprove: (id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type AccountCancelQueryFilter = {
  userId: string;
};

const AccountCancelFilter = () => {
  const { clear, push } = useQueryParams<AccountCancelQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: AccountCancelQueryFilter & { page: number }) => {
    push({
      ...values,
    });
  };

  return (
    <FilterWrap
      initialValues={{
        userId: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput name="firstName" label="Usuário" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const AccountCancelTable = ({ list, loading, totalCount, onDeny, onApprove }: Props) => {
  return (
    <>
      <AccountCancelFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Usuario" sort="userId" />
                  <TableCellHead label="Data de Criação da Solicitação" sort="createdAt" />
                  <TableCellHead label="Status" sort="status" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((accountCancel) => (
                  <TableRow hover key={accountCancel.status}>
                    <TableCell>{`${accountCancel.userFK?.firstName} ${accountCancel.userFK?.lastName}`}</TableCell>
                    <TableCell>{formatDate(accountCancel.createdAt)}</TableCell>
                    <TableCell>{formatStatusUser(accountCancel.status)}</TableCell>
                    <DenyRecord
                      onDeny={() => onDeny(accountCancel.id)}
                      disabled={accountCancel.status != 'PENDING'}
                      hoverText="Cancelar Solicitação"
                    />
                    <ApproveRecord
                      onApprove={() => onApprove(accountCancel.id)}
                      disabled={accountCancel.status != 'PENDING'}
                      hoverText="Aprovar Solicitação"
                    />{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const AccounCancelList = (props: any) => {
  const [state, actions] = useAccountsCancel();
  const [, snackbarActions] = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleDeny(accountId: string) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`account-cancel/${accountId}`, {
      status: 'DENIED',
    });
    if (res.ok) {
      snackbarActions.setMessage('Usuário Reprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleApprove(accountId: string) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`account-cancel/${accountId}`, {
      status: 'APPROVED',
    });
    if (res.ok) {
      snackbarActions.setMessage('Usuário Aprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return (
        <AccountCancelTable
          onApprove={() => {}}
          onDeny={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      );
    case 'with-data':
      return (
        <AccountCancelTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onApprove={handleApprove}
          onDeny={handleDeny}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default AccounCancelList;
