import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import TextInput from '../../../components/TextInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { faqRepository } from '../hooks/faqRepository';
import { useFaq } from '../hooks/useFaq';
import { Faq } from '../types';

const schema = yup.object().shape({
  title: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  description: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const FaqForm = () => {
  const history = useHistory();
  const [faqState] = useFaq();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<Faq>(faqRepository.save);

  const handleSubmit = async (faq: any) => {
    setLoading(true);
    await save(faq);
    setLoading(false);
  };

  const formatData = (values: Faq) => {
    return values;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues = {
    title: '',
    description: '',
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={faqState.tag === 'with-data' ? formatData(faqState.entity) : initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="title" label="Título" />
              </Grid>
              <Grid item xs={12}>
                <TextInput name="description" label="Descrição" minLength={13} multiple />
              </Grid>
            </Grid>
            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
