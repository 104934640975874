import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Paper } from '../../../components';
import EmailInput from '../../../components/Formik/Forms/Inputs/EmailInput';
import PasswordInput from '../../../components/Formik/Forms/Inputs/PasswordInput';
import SelectInput from '../../../components/Formik/Forms/SelectInput';
import TextInput from '../../../components/Formik/Forms/TextInput';
import yupValidation from '../../../utils/yupValidation';
import { MessagesYup } from '../../messages';
import { managerRepository } from '../hooks/managerRepository';
import { useManager } from '../hooks/useManager';
import { Manager } from '../types';

const schema = yup.object().shape({
  firstName: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  email: yup.string().email().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  password: yup.string().trim().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  role: yup.string().trim().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
});

export const role = [
  {
    label: 'Admin',
    value: 'ADMIN',
  },
];

export const ManagerForm = () => {
  const history = useHistory();
  const [managerState] = useManager();
  const [, setLoading] = useState<boolean>(false);

  const { save } = useEntitySaver<Manager>(managerRepository.save);

  const handleSubmit = async (manager: any) => {
    setLoading(true);
    await save({
      ...manager,
    });
    setLoading(false);
    history.goBack();
  };

  const formatData = (values: Manager) => {
    return {
      ...values,
    };
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const initialValues = {
    email: '',
    firstName: '',
    role: '',
  };

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        managerState.tag === 'with-data' ? formatData(managerState.entity) : initialValues
      }
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextInput name="firstName" label="Primeiro Nome" />
              </Grid>
              <Grid item xs={4}>
                <SelectInput options={role} name="role" label="Papel" />
              </Grid>
              <Grid item xs={6}>
                <EmailInput name="email" label="Email" />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput name="password" label="Senha" />
              </Grid>
            </Grid>

            <Grid justifyContent="flex-end" item container spacing={2} style={{ marginTop: 8 }}>
              <DialogActions>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Salvar'}
                </Button>
              </DialogActions>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
