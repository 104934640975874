import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { formatPercent } from '../../utils/formatPercent';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useTraderPositions } from './hooks/useTraders';
import { TraderReplicationPositionDetail } from './types';

interface Props {
  list: TraderReplicationPositionDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

export const TraderPositionsTable = ({ list, loading, totalCount }: Props) => {
  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Simbolo" sort="" />
                  <TableCellHead label="Quantidade em Carteira" sort="" />
                  <TableCellHead label="Preço de Entrada" sort="" />
                  <TableCellHead label="Preço de Mercado" sort="" />
                  <TableCellHead label="Granho/Perca" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replicationCycle) => (
                  <TableRow key={replicationCycle.id}>
                    <TableCell>{replicationCycle.symbol}</TableCell>
                    <TableCell>{replicationCycle.positionAmt}</TableCell>
                    <TableCell>{formatedValueUSDT(replicationCycle.entryPrice)}</TableCell>
                    <TableCell>{formatedValueUSDT(replicationCycle.markPrice)}</TableCell>
                    <TableCell>{formatedValueUSDT(replicationCycle.unRealizedProfit)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const TraderPositionsList = (props: any) => {
  const [state, actions] = useTraderPositions();
  const params = useParams<{ id: string; childId: string }>();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  switch (state.tag) {
    case 'empty':
      return <TraderPositionsTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <TraderPositionsTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TraderPositionsList;
