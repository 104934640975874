import { IoIosArrowDown } from 'react-icons/io';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import { useSort } from '@elentari/core';

const CustomTableCell = withStyles(() => ({
  root: {
    color: '',
    fontWeight: 'bold',
    fontSize: 14,
  },
}))(TableCell);

interface TableCellHeadProps {
  visible?: boolean;
  label: string;
  sort?: string;
  className?: any;
}

/**
 * @param label word to show in the column
 * @param sort word that will go in the URL for API
 */

export function TableCellHead({ visible = true, label, sort, className }: TableCellHeadProps) {
  const [state, { sortBy }] = useSort();

  return (
    <>
      { visible && (
        <CustomTableCell className={className}>
          {sort ? (
            <TableSortLabel
              IconComponent={IoIosArrowDown}
              direction={state.direction}
              onClick={() => sortBy(sort)}
            >
              {label}
            </TableSortLabel>
          ) : (
            label
          )}
        </CustomTableCell>
      )}
    </>
  );
}
