import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import { useState } from 'react';
import SimpleDialog from './SimpleDialog';

type Props = {
  onApprove: () => void;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function ApproveRecord({
  onApprove: onApprove,
  title,
  message,
  hoverText,
  disabled,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={hoverText || 'Aprovar usuário'} aria-label={hoverText || 'Aprovar usuário'}>
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <CheckIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente aprovar esse usuário?'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onApprove}
        title={title || 'Aprovar este Usuário'}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
