import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
}));

type Props = {
  onRefresh: () => void; 
};

export const RefreshRecord = ({ onRefresh }: Props) => {
  const classes = useStyles();
  const handleRefreshClick = () => onRefresh();

  return (
    <Tooltip title="Atualizar" aria-label="Atualizar">
      <Fab
        color="primary"
        aria-label="Refresh"
        className={classes.fab}
        onClick={handleRefreshClick}
      >
        <RefreshIcon />
      </Fab>
    </Tooltip>
  );
};
