import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import { parse } from 'qs';
import { useEffect } from 'react';
import { ApproveRecord, DenyRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import SelectInput, { SelectOption } from '../../components/Formik/Forms/SelectInput';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { apiSauceInstance } from '../../services/api';
import { formatDate } from '../../utils/format-date';
import { formatTransferStatus } from '../../utils/formatTransferStatus';
import { formatedValueUSDT } from '../../utils/formatValue';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useTransfers } from './hooks/useTransfers';
import { TransferDetail, TransferStatus } from './types';

interface Props {
  list: TransferDetail[];
  loading: boolean;
  totalCount: number;
  onDeny: (id: TransferDetail) => void;
  onApprove: (id: TransferDetail) => void;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type TransferQueryFilter = {
  status: TransferStatus;
};

const TransferFilter = () => {
  const { clear, push } = useQueryParams<TransferQueryFilter>();

  const status: TransferStatus[] = ['PENDING', 'EXECUTED', 'DENIED'];
  const statusSelect: SelectOption[] = status.map((s) => ({
    label: formatTransferStatus(s),
    value: s,
  }));

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: TransferQueryFilter & { page: number }) => {
    push(values);
  };

  return (
    <FilterWrap
      initialValues={{
        status: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput options={statusSelect} name="status" label="Situação" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const TransferTable = ({ list, loading, totalCount, onDeny, onApprove }: Props) => {
  return (
    <>
      <TransferFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="firstName" />
                  <TableCellHead label="Data de Requisição" sort="requestDate" />
                  <TableCellHead label="Valor" sort="amount" />
                  <TableCellHead label="Endereço da Carteira" sort="walletAddress" />
                  <TableCellHead label="Situação" sort="status" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((transfer) => {
                  return (
                    <TableRow hover key={transfer.id}>
                      <TableCell>
                        {`${transfer.userFK?.firstName} ${transfer.userFK?.lastName}`}
                      </TableCell>
                      <TableCell>{formatDate(transfer.requestDate)}</TableCell>
                      <TableCell>{formatedValueUSDT(transfer.amount)}</TableCell>
                      <TableCell>{transfer.userFK?.walletAddress}</TableCell>
                      <TableCell>{formatTransferStatus(transfer.status)}</TableCell>
                      {transfer.status === 'PENDING' ? (
                        <>
                          <DenyRecord
                            onDeny={() => onDeny(transfer)}
                            title="Reprovar esta Transferência?"
                            message="Deseja realmente reprovar esta transferência?"
                            hoverText="Reprovar transferência"
                          />
                          <ApproveRecord
                            onApprove={() => onApprove(transfer)}
                            title="Aprovar esta Transferência?"
                            message="Deseja realmente aprovar esta transferência?"
                            hoverText="Aprovar transferência"
                          />
                        </>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

const TransferList = () => {
  const [state, actions] = useTransfers();
  const [, snackbarActions] = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleDeny(transfer: TransferDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`transfers/${transfer.id}`, {
      approved: false,
    });
    if (res.ok) {
      snackbarActions.setMessage('Transferência recusada com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleApprove(transfer: TransferDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`transfers/${transfer.id}`, {
      approved: true,
    });
    if (res.ok) {
      snackbarActions.setMessage('Transferência executada com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return (
        <TransferTable
          onApprove={() => {}}
          onDeny={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      );
    case 'with-data':
      return (
        <TransferTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onApprove={handleApprove}
          onDeny={handleDeny}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TransferList;
