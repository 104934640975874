import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { ListTileProps } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[800],
  },
  textColor: {
    color: theme.palette.white,
  },
  icon: {
    marginLeft: 5,
    fontSize: 40,
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatarName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 12,
  },
  action: {
    float: 'right',
  },
}));

const ListTile = ({ name, logout }: ListTileProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const temporaryAvatar = '';
  const avatar = temporaryAvatar;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    logout();
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onClickHelper = (onClick: any) => ({
    root: clsx({
      [classes.clickable]: typeof onClick === 'function',
    }),
  });

  const onNameClick = () => {};

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item className={classes.container}>
        <Grid container>
          <Grid item className={classes.avatarName}>
            <Typography
              variant="body1"
              color="inherit"
              onClick={onNameClick}
              classes={onClickHelper(onNameClick)}
            >
              {name}
            </Typography>
          </Grid>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {avatar ? (
              <Avatar className={classes.icon} alt="Avatar" src={avatar} />
            ) : (
              <Avatar alt={`${name}`} src={''}>
                {name?.[0] || ''}
              </Avatar>
            )}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {/* <MenuItem onClick={handleClose}>Perfil</MenuItem> */}
                      {/* <MenuItem onClick={handleClose}>Minha conta</MenuItem> */}
                      <MenuItem onClick={handleLogout}>Sair</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListTile;
