import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { CreditAccountHistoryDetail, CreditAccountHistoryForm } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const creditAccountOperationHistoryRepository = makeRepository<
  EdgesPage<CreditAccountHistoryDetail>,
  CreditAccountHistoryForm,
  CreditAccountHistoryDetail,
  number
>({ resource: 'credit-account-history', mapQueryParams }, apiSauceInstance);

export const creditAccountOperationRepository = makeRepository<
  EdgesPage<CreditAccountHistoryDetail>,
  CreditAccountHistoryForm,
  CreditAccountHistoryDetail,
  number
>({ resource: 'credit-account', mapQueryParams }, apiSauceInstance);
