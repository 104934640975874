export interface User {
  id: number;
  email: string;
  semId: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneRegion: string;
  phone: string;
  cpf: string;
  apiKey: string;
  secretKey: string;
  test: boolean;
  limit: boolean;
  birthDate: Date;
  walletAddress?: string;
  profilePhoto?: string;
  description?: string;
  status: string;
  walletVolume?: number;
  goalProfit?: number | null;
  createdAt: Date;
  referrerFK?: User;
}

export const status = [
  {
    label: 'Pendente',
    value: 'PENDING',
  },
  {
    label: 'Reprovado',
    value: 'DENIED',
  },
  {
    label: 'Aprovado',
    value: 'APPROVED',
  },
];

export interface IUserForm {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  cpf: string;
  apiKey?: string;
  secretKey?: string;
  test: boolean;
  limit: boolean;
  birthDate: Date;
  walletAddress?: string;
  goalProfit?: number | null;
  phoneRegion: string;
  phone: string;
  profilePhoto?: string;
  referrerCode?: string;
}

export interface UserDetail extends User {
  id: number;
  replicationTrader?: User;
  replicationInvestor?: User;
  referrerFK?: User;
  creditAccount: {
    address: string;
    userId: number;
    balance: number;
  };
  futuresAccount: {
    address: string;
    userId: number;
    balance: number;
  };
}

export interface UserSupportDetail {
  id: number;
  userId: number;
  userFK: UserDetail;
  createdAt: Date;
  support: string;
  managerId: number;
  managerFK: UserDetail;
}

export interface SaveUserSupportInput {
  id?: number;
  support: string;
}

export interface UserFuturesAccountDetail {
  address: string;
  balance: string;
}
