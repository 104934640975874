import { TableCell } from "@material-ui/core";
import { vi } from "date-fns/locale";
import { formatPercent } from "../utils/formatPercent";

type Props = {
    visible?: boolean;
    value?: number;
}

export function TableCellPercent({visible = true, value = 0}: Props) {
    return (
        <>
            {visible && (
              <TableCell>
                {formatPercent(value)}
              </TableCell>
            )}
        </>
      );
}