import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

interface CustomAction {
  label: string;
  classes: string;
  onClick: () => void;
}

export const FormWrap = ({
  children,
  handleSubmit,
  nameButton,
  disabeSubmit = false,
  disableBack = false,
  loading = false,
  hideConfirmButton = false,
  customActions = [],
}: {
  children: ReactNode;
  handleSubmit: any;
  disableBack?: boolean;
  loading?: boolean;
  disabeSubmit?: boolean;
  hideConfirmButton?: boolean;
  nameButton?: string;
  customActions?: CustomAction[];
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  function handleGoBack() {
    const newLocation = location.pathname.split('/').filter(Boolean).slice(0, -1).join('/');

    history.push(`/${newLocation}`);
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} direction="column">
        <Grid item>{children}</Grid>

        <Grid item xs={12}>
          <Grid justifyContent="flex-end" container spacing={2}>
            <Grid item style={{ width: 160 }}>
              <Button
                fullWidth
                type="button"
                variant="outlined"
                onClick={handleGoBack}
                disabled={disableBack || loading}
              >
                Voltar
              </Button>
            </Grid>
            {customActions.map((action) => (
              <Grid item style={{ width: 160 }}>
                <Button
                  fullWidth
                  type="button"
                  variant="outlined"
                  onClick={action.onClick}
                  className={action.classes}
                >
                  {action.label}
                </Button>
              </Grid>
            ))}
            <Grid item style={{ width: 160 }}>
              {loading ? (
                <Button
                  data-testid="salvar"
                  fullWidth
                  disabled={true}
                  variant="contained"
                  color="primary"
                >
                  <div className={classes.root}>
                    <CircularProgress color="inherit" size={24} />
                  </div>
                </Button>
              ) : (
                <Button
                  data-testid="salvar"
                  fullWidth
                  type="submit"
                  disabled={disabeSubmit}
                  variant="contained"
                  color="primary"
                >
                  {nameButton ?? 'Salvar'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
