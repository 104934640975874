import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AnimationIcon from '@mui/icons-material/Animation';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BadgeIcon from '@mui/icons-material/Badge';
import CodeIcon from '@mui/icons-material/Code';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import Money from '@mui/icons-material/Money';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleIcon from '@mui/icons-material/People';
import { Item } from '../../components/AppWrap/MenuItems';
import AccounCancelList from '../account-cancel/AccountCancelList';
import AddressList from '../address/AddressList';
import { AddressMasterDetail } from '../address/master-detail/AddressMasterDetail';
import CreditAccountOperationList from '../credit-account-credit-debit/CreditAccountOperationList';
import { CreditAccountOperationMasterDetail } from '../credit-account-credit-debit/master-detail/CreditAccountOperationMasterDetail';
import FaqList from '../faqs/FaqList';
import { FaqMasterDetail } from '../faqs/master-detail/FaqMasterDetail';
import ManagerList from '../manager/ManagerList';
import { ManagerMasterDetail } from '../manager/master-detail/ManagerMasterDetail';
import { ReplicationCycleMasterDetail } from '../replication-cycle/master-detail/ReplicationCycleMasterDetail';
import ReplicationCycleList from '../replication-cycle/ReplicationCycleList';
import { ReplicationMasterDetail } from '../replication/master-detail/ReplicationMasterDetail';
import ReplicationList from '../replication/ReplicationList';
import { TermMasterDetail } from '../terms/master-detail/TermMasterDetail';
import TermList from '../terms/TermList';
import { TraderBinanceCredencialsMasterDetail } from '../trader-binance-api-update/master-detail/TraderBinanceCredencialsMasterDetail';
import TraderBinanceCredencialsList from '../trader-binance-api-update/TraderBinanceCredencialsList';
import { TraderMasterDetail } from '../trader/master-detail/TraderMasterDetail';
import TraderList from '../trader/TraderList';
import TransferList from '../transfer/TransferList';
import { UserBinanceCredencialsMasterDetail } from '../user-binance-api-update/master-detail/UserBinanceCredencialsMasterDetail';
import UserBinanceCredencialsList from '../user-binance-api-update/UserBinanceCredencialsList';
import { UserMasterDetail } from '../user/master-detail/UserMasterDetail';
import UserList from '../user/UserList';
import { VideoMasterDetail } from '../videos/master-detail/VideoMasterDetail';
import VideoList from '../videos/VideoList';

const menuItems: Item[] = [
  {
    name: 'manager',
    label: 'Manager',
    pathname: '/manager',
    icon: AssignmentIndIcon,
    group: false,
    detail: ManagerMasterDetail,
    list: ManagerList,
  },
  {
    name: 'user-options',
    label: 'Investidores',
    icon: PeopleIcon,
    group: true,
    items: [
      {
        name: 'users',
        label: 'Usuários',
        pathname: '/users',
        icon: PeopleIcon,
        group: false,
        detail: UserMasterDetail,
        list: UserList,
      },
      {
        name: 'users-binance-credencials',
        label: 'Credencial Binance Usuários',
        pathname: '/users-binance-credencials',
        icon: PeopleIcon,
        group: false,
        detail: UserBinanceCredencialsMasterDetail,
        list: UserBinanceCredencialsList,
      },
    ],
  },
  {
    name: 'professionals-options',
    label: 'Profissionais',
    icon: BadgeIcon,
    group: true,
    items: [
      {
        name: 'professionals',
        label: 'Traders',
        pathname: '/professionals',
        icon: PeopleIcon,
        group: false,
        detail: TraderMasterDetail,
        list: TraderList,
      },
      {
        name: 'professionals-binance-credencials',
        label: 'Credencial Binance Trader',
        pathname: '/professionals-binance-credencials',
        icon: PeopleIcon,
        group: false,
        detail: TraderBinanceCredencialsMasterDetail,
        list: TraderBinanceCredencialsList,
      },
    ],
  },

  {
    name: 'replications',
    label: 'Replicações',
    icon: FlipCameraAndroidIcon,
    group: true,
    items: [
      {
        name: 'replication',
        label: 'Replicação',
        pathname: '/replication',
        icon: CodeIcon,
        group: false,
        detail: ReplicationMasterDetail,
        list: ReplicationList,
      },
      {
        name: 'replication-cycle',
        label: 'Ciclo de Replicação',
        pathname: '/replication-cycle',
        icon: AnimationIcon,
        group: false,
        list: ReplicationCycleList,
        detail: ReplicationCycleMasterDetail,
      },
    ],
  },
  {
    name: 'personalization',
    label: 'Personalização',
    icon: AutoFixHighIcon,
    group: true,
    items: [
      {
        name: 'addresses',
        label: 'Endereços RD',
        pathname: '/addresses',
        icon: AccountBalanceWalletIcon,
        group: false,
        detail: AddressMasterDetail,
        list: AddressList,
      },
      {
        name: 'faqs',
        label: 'FAQ',
        pathname: '/faqs',
        icon: ContactSupportIcon,
        group: false,
        detail: FaqMasterDetail,
        list: FaqList,
      },
      {
        name: 'terms',
        label: 'Termos',
        pathname: '/terms',
        icon: AssignmentTurnedInIcon,
        group: false,
        detail: TermMasterDetail,
        list: TermList,
      },
      {
        name: 'videos',
        label: 'Vídeos',
        pathname: '/videos',
        icon: OndemandVideoIcon,
        group: false,
        detail: VideoMasterDetail,
        list: VideoList,
      },
    ],
  },

  {
    name: 'lancamentos',
    label: 'Lançamentos',
    icon: Money,
    group: true,
    items: [
      {
        name: 'transfers',
        label: 'Transferências',
        pathname: '/transfers',
        icon: CompareArrowsIcon,
        group: false,
        list: TransferList,
      },
      {
        name: 'credit-account-history',
        label: 'Lançamentos Débito/Crédito',
        pathname: '/credit-account-history',
        icon: CompareArrowsIcon,
        group: false,
        list: CreditAccountOperationList,
        detail: CreditAccountOperationMasterDetail,
      },
    ],
  },
  {
    name: 'account-delete',
    label: 'Cancelamento de Conta',
    pathname: '/account-delete',
    icon: DoDisturbAltIcon,
    group: false,
    list: AccounCancelList,
  },
];

export default menuItems;
