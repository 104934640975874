import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { AddressDetail, IAddressForm } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const addressRepository = makeRepository<
  EdgesPage<AddressDetail>,
  IAddressForm,
  AddressDetail,
  number
>({ resource: 'rd-addresses', mapQueryParams }, apiSauceInstance);

export const getAllRdaddressesTypes = async () => {
  const response = await apiSauceInstance.get<any>(`/rd-addresses/types`, {
    headers: {
      Accept: 'application/json',
    },
  });
  const { data: info, ok, status } = response;

  if (info && ok) {
    return {
      info,
      ok,
      status,
    };
  }
  return null;
};
