import { Pagination } from './pagination';
import { Sort } from './sort';
import { SortDirection } from './sort-direction';

export class URLSearchParamsDecorator {
  private params: URLSearchParams;

  constructor(search: string) {
    this.params = new URLSearchParams(search);
  }

  get pagination(): Pagination {
    return new Pagination(
      Number(this.params.get('page')) || 1,
      Number(this.params.get('rows')) || 10,
    );
  }

  get sort(): Sort | undefined {
    const paramKeysArray = Array.from(this.params.keys());

    for (const key of paramKeysArray) {
      if (key.startsWith('sort[') && key.endsWith(']')) {
        const direction =
          this.params.get(key)?.toLocaleUpperCase() === 'DESC'
            ? SortDirection.DESC
            : SortDirection.ASC;

        const field = key.replace('sort[', '').replace(']', '');

        return new Sort(field, direction);
      }
    }

    return undefined;
  }
}
