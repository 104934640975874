import { ReactElement } from 'react';
import { FetchState } from '@elentari/core/types';
import { HandleErrorPage } from './handleErrorPage';
export function handleStateErrorsToRender<T>(
  state: FetchState<T>,
  component: ReactElement,
): ReactElement {
  if (state.tag === 'error') {
    return <HandleErrorPage state={state.data} />;
  }
  if (state.tag === 'empty-response') {
    return <span> Empty response </span>;
  }
  return component;
}
