import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import SimpleDialog from './SimpleDialog';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type Props = {
  onToggle: () => void;
  title?: string;
  message?: string;
  hoverText?: string;
  disabled?: boolean;
};

export function StartReplicationRecord({ onToggle: onToggle, title, message, hoverText, disabled }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={hoverText || `Iniciar replicação.`} aria-label={hoverText || `Iniciar replicação.`}>
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <PlayArrowIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || `Deseja realmente iniciar a replicação?`}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onToggle}
        title={title || `Iniciar replicação`}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
