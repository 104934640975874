export class Pagination {
  constructor(public page: number, public rows: number) {}

  get start(): number {
    return (this.page - 1) * this.rows;
  }

  get end(): number {
    return this.start + this.rows;
  }
}
