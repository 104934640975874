import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { ManagerForm } from './ManagerForm';
import { useManager } from '../hooks/useManager';

export function ManagerMasterDetail() {
  const [managerState, managerActions] = useManager();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      managerState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.email,
    ),
  ]);
  useFetchAndReset(managerActions.fetch, managerActions.reset);
  return handleStateErrorsToRender(managerState, <ManagerForm />);
}
