import { InputBaseComponentProps, InputLabelProps, TextField } from '@material-ui/core';
import { useField } from 'formik';

interface TextInputProps {
  id?: string;
  multiline?: boolean;
  rows?: number;
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  type?: string;
  inputProps?: InputBaseComponentProps;
  InputLabelProps?: Partial<InputLabelProps>;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  minLength?: number;
  multiple?: boolean;
}

function TextInput(props: TextInputProps) {
  const [field, meta] = useField(props);

  return (
    <TextField
      variant="outlined"
      fullWidth
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

export default TextInput;
