import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { FaqForm } from './FaqForm';
import { useFaq } from '../hooks/useFaq';

export function FaqMasterDetail() {
  const [faqState, faqActions] = useFaq();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      faqState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(faqActions.fetch, faqActions.reset);
  return handleStateErrorsToRender(faqState, <FaqForm />);
}
