import { EdgesPage } from '@elentari/core/types';
import { makeRepository, makeSubRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { IUserForm, SaveUserSupportInput, UserDetail, UserSupportDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const userRepository = makeRepository<EdgesPage<UserDetail>, IUserForm, UserDetail, number>(
  { resource: 'users', mapQueryParams },
  apiSauceInstance,
);

export const supportRepository = makeSubRepository<
  EdgesPage<UserSupportDetail>,
  SaveUserSupportInput,
  UserSupportDetail,
  number,
  number
>({ resource: 'users/:id/support', mapQueryParams }, apiSauceInstance);
