import { useQueryParams, useSnackbar } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApiResponse } from 'apisauce';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, ApproveRecord, DenyRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import MaskInput from '../../components/Formik/Forms/MaskInput';
import SelectInput from '../../components/Formik/Forms/SelectInput';
import TextInput from '../../components/Formik/Forms/TextInput';
import { HomologationRecord } from '../../components/HomologationRecord';
import NoResultsTable from '../../components/NoResultsTable';
import { OpenToReplication } from '../../components/OpenToReplicationRecord';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import { apiSauceInstance } from '../../services/api';
import { formatCpfCnpj } from '../../utils/format-cpf';
import { formatStatusUser } from '../../utils/formatStatusUser';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useTraders } from './hooks/useTraders';
import { status, TraderDetail } from './types';

interface Props {
  list: TraderDetail[];
  loading: boolean;
  totalCount: number;
  onDeny: (id: TraderDetail) => void;
  onHomologation: (id: TraderDetail) => void;
  onApprove: (id: TraderDetail) => void;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type TraderQueryFilter = {
  firstName: string;
  email: string;
  cpf: string;
  status: string;
};

const TraderFilter = () => {
  const { clear, push } = useQueryParams<TraderQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: TraderQueryFilter & { page: number }) => {
    push({
      ...values,
      cpf: values.cpf.replaceAll(/\D/g, ''),
    });
  };

  return (
    <FilterWrap
      initialValues={{
        cpf: '',
        firstName: '',
        email: '',
        status: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextInput name="firstName" label="Primeiro Nome" />
        </Grid>
        <Grid item xs={3}>
          <TextInput name="email" label="Email" />
        </Grid>
        <Grid item xs={3}>
          <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
        </Grid>
        <Grid item xs={3}>
          <SelectInput options={status} name="status" label="Status" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const TraderTable = ({
  list,
  loading,
  totalCount,
  onDeny,
  onHomologation,
  onApprove,
  authorization,
}: Props) => {
  return (
    <>
      <TraderFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Nome" sort="firstName" />
                  <TableCellHead label="E-mail" sort="email" />
                  <TableCellHead label="CPF" sort="cpf" />
                  <TableCellHead label="Situação" sort="status" />
                  <TableCellHead label="" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((trader) => (
                  <TableRow hover key={trader.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/professionals/${trader.id}`}>
                        {`${trader.firstName} ${trader.lastName}`}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{trader.email}</TableCell>
                    <TableCell>{formatCpfCnpj(trader.cpf)}</TableCell>
                    <TableCell>{formatStatusUser(trader.status)}</TableCell>
                    <HomologationRecord
                      onHomologation={() => onHomologation(trader)}
                      disabled={trader.status == 'OFF' || trader.status == 'HOMOLOGATION'}
                    />
                    <DenyRecord
                      onDeny={() => onDeny(trader)}
                      disabled={trader.status == 'OFF' || trader.status == 'DENIED'}
                    />
                    <ApproveRecord
                      onApprove={() => onApprove(trader)}
                      disabled={trader.status == 'OFF' || trader.status == 'APPROVED'}
                    />{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="professionals" authorization={authorization} />
    </>
  );
};

const TraderList = () => {
  const [state, actions] = useTraders();
  const [, snackbarActions] = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  async function handleDeny(trader: TraderDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(`professionals/${trader.id}/deny`, {
      status: 'DENIED',
    });
    if (res.ok) {
      snackbarActions.setMessage('Usuário Reprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleHomologation(trader: TraderDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(
      `professionals/${trader.id}/homologation`,
      {
        status: 'HOMOLOGATION',
      },
    );
    if (res.ok) {
      snackbarActions.setMessage('Usuário colocado em período de homologação com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  async function handleApprove(trader: TraderDetail) {
    const res: ApiResponse<any> = await apiSauceInstance.patch(
      `professionals/${trader.id}/approve`,
      {
        status: 'APPROVED',
      },
    );
    if (res.ok) {
      snackbarActions.setMessage('Usuário Aprovado com sucesso!');
      actions.fetchPage({});
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return (
        <TraderTable
          onApprove={() => {}}
          onDeny={() => {}}
          onHomologation={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
        />
      );
    case 'with-data':
      return (
        <TraderTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onApprove={handleApprove}
          onDeny={handleDeny}
          onHomologation={handleHomologation}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default TraderList;
