import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableContainerMD from '@material-ui/core/TableContainer';
import React, { ReactNode } from 'react';
import Pagination from './Pagination';
import Spacer from './Spacer';
import When from './When';

export const TableContainer = ({
  children,
  loading,
  totalCount,
  disablePagination,
}: {
  children: ReactNode;
  totalCount: number;
  loading: boolean;
  disablePagination?: boolean;
}) => {
  return (
    <TableContainerMD>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {children}
      <Spacer y={2} />
      <Grid container justifyContent="center">
        <Grid item>{!disablePagination && <Pagination count={totalCount} />}</Grid>
      </Grid>
      <Spacer y={2} />
    </TableContainerMD>
  );
};
