export const formatedValue = (value: number, formatoMonetario: boolean, locale?: 'USD' | 'BRL') => {
  if (value === undefined) {
    return '';
  }

  if (formatoMonetario) {
    if (locale === 'USD') {
      return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
        .format(value)
        .replace('$', '');
    }
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value)
      .replace('R$', '');
  } else {
    return value;
  }
};

export const formatedValueUSDT = (value: number | bigint) => {
  if (value === undefined) {
    return '';
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 8,
  })
    .format(Number(value) / Math.pow(10, 8))
    .replace('U$', '');
};
