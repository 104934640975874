import { EdgesPage } from '@elentari/core/types';
import { makeRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { ReplicationCycleDetail, ReplicationCycle } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const replicationCycleRepository = makeRepository<
  EdgesPage<ReplicationCycleDetail>,
  ReplicationCycle,
  ReplicationCycleDetail,
  number
>({ resource: 'replication-cycle', mapQueryParams }, apiSauceInstance);
