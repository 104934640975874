import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import { apiSauceInstance } from '../../services/api';
import { formatedValueUSDT } from '../../utils/formatValue';
import { TableCellUSDT } from '../../components/TableCellUSDT';
import { TableCellPercent } from '../../components/TableCellPercent';
import { RefreshRecord } from '../../components/RefreshRecord';
import { formatDateTime } from '../../utils/format-date-time';
import { URLSearchParamsDecorator } from '../../utils/url-search-params-decorator';
import { Sort } from '../../utils/sort';
import { SortDirection } from '../../utils/sort-direction';

interface ResponseData {
  edges: Edge[];
}

interface Edge {
  node: Position;
}

interface Position {
  symbol: string;
  side: string;
  leverage: string;
  amount: number;
  entryPrice: number;
  markPrice: number;
  liquidationPrice: number;
  marginRate: number;
  margin: number;
  marginType: string;
  unrealizedProfit: number;
  maintMargin: number;
  marginBalance: number;
  updateDate: string;
  investor: {
    id: number;
    semId: string;
    firstName: string;
    type: string;
  };
}

interface Props {
  list: Position[];
  loading: boolean;
  totalCount: number;
  refreshPositions: () => void;
}

export const ReplicationPositionsTable = ({
  list,
  loading,
  totalCount,
  refreshPositions,
}: Props) => {
  return (
    <Paper noTopBorderRadius>
      <TableContainer loading={loading} totalCount={totalCount}>
        {list.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead label="Investidor" sort="investor" />
                <TableCellHead label="Simbolo" sort="symbol" />
                <TableCellHead label="Tamanho" sort="amount" />
                <TableCellHead label="Preço entrada" sort="entryPrice" />
                <TableCellHead label="Preço Ref." sort="markPrice" />
                <TableCellHead label="Preço Liq." sort="liquidationPrice" />
                <TableCellHead label="Tx. Margem" sort="marginRate" />
                <TableCellHead label="Margem" sort="maintMargin" />
                <TableCellHead label="Ganhos e Perdas" sort="unrealizedProfit" />
                <TableCellHead label="Margem Manut." sort="maintMargin" />
                <TableCellHead label="Saldo de Margem" sort="marginBalance" />
                <TableCellHead label="Data de Atualização" sort="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((position, index) => (
                <TableRow
                  hover
                  key={`${position.investor.firstName}:${position.investor.semId}:${position.symbol}:${index}`}
                >
                  <TableCell>
                    <LinkMD
                      component={Link}
                      to={
                        position.investor.type === 'TRADER'
                          ? `/professionals/${position.investor.id}`
                          : `/users/${position.investor.id}`
                      }
                    >
                      {position.investor.semId} - {position.investor.firstName}
                    </LinkMD>
                  </TableCell>
                  <TableCell>
                    {position.symbol}|{position.side}|{position.leverage}x
                  </TableCell>
                  <TableCell>{position.amount}</TableCell>
                  <TableCellUSDT value={position.entryPrice} />
                  <TableCellUSDT value={position.markPrice} />
                  <TableCellUSDT value={position.liquidationPrice} />
                  <TableCellPercent value={position.marginRate} />
                  <TableCell>
                    {formatedValueUSDT(position.maintMargin)}|{position.marginType}
                  </TableCell>
                  <TableCellUSDT value={position.unrealizedProfit} />
                  <TableCellUSDT value={position.maintMargin} />
                  <TableCellUSDT value={position.marginBalance} />
                  <TableCell>{formatDateTime(new Date(position.updateDate))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoResultsTable loading={loading} />
        )}
      </TableContainer>

      <RefreshRecord onRefresh={refreshPositions}></RefreshRecord>
    </Paper>
  );
};

export const ReplicationPositions = () => {
  const [positions, setPositions] = useState<Position[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string; childId: string }>();
  const searchParams = new URLSearchParamsDecorator(window.location.search);
  const pagination = searchParams.pagination;
  const sort = searchParams.sort || new Sort('investor', SortDirection.ASC);

  const fetchPositions = async () => {
    setLoading(true);
    const response = await apiSauceInstance.get<ResponseData>(
      `/replication-cycle/${params.id}/positions`,
    );

    setLoading(false);

    if (!response.data) return console.log('error');
    setPositions(response.data.edges.map((item) => item.node));
  };

  useEffect(() => {
    setTimeout(() => {
      if (!positions.length || positions.length <= 0) {
        fetchPositions();
      }
    }, 1000);
  }, [window.location.search]);

  if (!positions.length || positions.length <= 0) {
    <ReplicationPositionsTable
      totalCount={0}
      list={[]}
      loading={loading}
      refreshPositions={fetchPositions}
    />;
  }

  const positionsSorted = positions.sort((left, right) => {
    switch (sort.field) {
      case 'investor':
        return sort.compare(left.investor.firstName, right.investor.firstName);
      case 'symbol':
        return sort.compare(left.symbol, right.symbol);
      case 'amount':
        return sort.compare(left.amount, right.amount);
      case 'entryPrice':
        return sort.compare(left.entryPrice, right.entryPrice);
      case 'markPrice':
        return sort.compare(left.markPrice, right.markPrice);
      case 'liquidationPrice':
        return sort.compare(left.liquidationPrice, right.liquidationPrice);
      case 'marginRate':
        return sort.compare(left.marginRate, right.marginRate);
      case 'maintMargin':
        return sort.compare(left.maintMargin, right.maintMargin);
      case 'unrealizedProfit':
        return sort.compare(left.unrealizedProfit, right.unrealizedProfit);
      case 'marginBalance':
        return sort.compare(left.marginBalance, right.marginBalance);
      default:
        return sort.compare(left.investor.firstName, right.investor.firstName);
    }
  });

  return (
    <ReplicationPositionsTable
      totalCount={positionsSorted.length}
      list={positionsSorted.slice(pagination.start, pagination.end)}
      loading={loading}
      refreshPositions={fetchPositions}
    />
  );
};
