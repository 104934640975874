import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { TermForm } from './TermForm';
import { useTerm } from '../hooks/useTerm';

export function TermMasterDetail() {
  const [termState, termActions] = useTerm();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      termState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(termActions.fetch, termActions.reset);
  return handleStateErrorsToRender(termState, <TermForm />);
}
