import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import NoResultsTable from '../../components/NoResultsTable';
import { TableCellHead } from '../../components/TableCellHead';
import { formatDate } from '../../utils/format-date';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useReplicationBound } from './hooks/useReplicationsBound';
import { PendingReplicationBoundDetail } from './types';
import { formatedValueUSDT } from '../../utils/formatValue';
import { AddPendingInvestorReplicationCycleRecord } from '../../components/AddPendingInvestorReplicationCycleRecord';
import { ApiResponse } from 'apisauce';
import { apiSauceInstance } from '../../services/api';
import { useSnackbar } from '@elentari/core';

interface Props {
  list: PendingReplicationBoundDetail[];
  loading: boolean;
  totalCount: number;
  onDelete: (parentId: string, id: string) => void;
  checks?: {
    ids: string[];
    onCheck: (id: string) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
  };
  onToggleAddInvestor: (id: PendingReplicationBoundDetail) => void;
}

export const ReplicationBoundTable = ({
  list,
  loading,
  totalCount,
  authorization,
  onToggleAddInvestor,
}: Props) => {
  const location = useLocation();
  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Investidor" sort="investorId" />
                  <TableCellHead label="Data de Início" sort="startDate" />
                  <TableCellHead label="Saldo em Carteira" sort="" />
                  <TableCellHead label="Indicação" sort="" />
                  <TableCellHead label="" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((replication) => (
                  <TableRow hover key={replication.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/users/${replication.investor.id}`}>
                        {`${replication.investor.semId} - ${replication.investor.firstName} ${replication.investor.lastName}`}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{formatDate(replication.startDate)}</TableCell>
                    <TableCell>
                      {formatedValueUSDT(replication.investor.totalWalletBalance)}
                    </TableCell>
                    <TableCell>
                      {replication?.referrer
                        ? `${replication.referrer.semId} - ${replication.referrer.firstName} ${replication.referrer.lastName}`
                        : ''}
                    </TableCell>
                    <AddPendingInvestorReplicationCycleRecord
                      onToggle={() => onToggleAddInvestor(replication)}
                      disabled={replication.cycle.status != 'ONGOING'}
                      message={`Deseja incluir o investidor ${replication.investor.semId} - ${replication.investor.firstName} no cilco de replicação?`}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={location.pathname} authorization={authorization} />
    </>
  );
};

export const ReplicationBound = () => {
  const [state, actions] = useReplicationBound();
  const params = useParams<{ id: string; childId: string }>();
  const [, snackbarActions] = useSnackbar();
  const [loadingAddInvestor, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({}, Number(params.id));
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const urlParam = new URLSearchParams(search);
      actions.fetchPage(parse(urlParam.toString()), Number(params.id));
    }, 1000);
  }, [window.location.search]);

  async function handleToggleAddInvestor(replication: PendingReplicationBoundDetail) {
    const path = `replication-cycle/${replication.cycle.id}/replication`;
    const body = { replicationId: replication.id };
    setLoading(true);
    const res: ApiResponse<any> = await apiSauceInstance.post(path, body);
    setLoading(false);
    if (res.ok) {
      snackbarActions.setMessage('Investidor adicionado ao ciclo com sucesso!');
      actions.fetchPage({}, Number(params.id));
    } else {
      snackbarActions.setMessage(res.data.message);
    }
  }

  switch (state.tag) {
    case 'empty':
      return (
        <ReplicationBoundTable
          onDelete={() => {}}
          totalCount={0}
          list={[]}
          loading={true}
          authorization={{ canCreate: false, canDelete: false }}
          onToggleAddInvestor={() => {}}
        />
      );
    case 'with-data':
      return (
        <ReplicationBoundTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading || loadingAddInvestor}
          onDelete={() => {}}
          authorization={{ canCreate: false, canDelete: false }}
          onToggleAddInvestor={handleToggleAddInvestor}
        />
      );
    case 'error':
      return <HandleErrorPage state={state.data} />;
    default:
      return <HandleErrorPage state={null} />;
  }
};
