import { makeUseFetchOne, makeUseFetchOneByParent } from '@elentari/core/hooks/makeUseFetchOne';
import { TraderDetail, TraderReplicationCycleDetail } from '../types';
import { replicationCycleRepository } from './replicationCycleRepository';
import { traderRepository } from './traderRepository';

export const useTrader = makeUseFetchOne<TraderDetail, number>({
  fetch: traderRepository.fetchOne,
});

export const useReplicationCycle = makeUseFetchOneByParent<
  TraderReplicationCycleDetail,
  number,
  number
>({
  fetch: replicationCycleRepository.fetchOne,
});
