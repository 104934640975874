import { TransferStatus } from './../modules/transfer/types';
export const formatTransferStatus = (value: TransferStatus) => {
  switch (value) {
    case 'DENIED':
      return 'Reprovado';
    case 'EXECUTED':
      return 'Executado';
    default:
      return 'Pendente';
  }
};
