import { useQueryParams } from '@elentari/core';
import { Grid } from '@material-ui/core';
import LinkMD from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'qs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AddRecord, Paper, TableContainer } from '../../components';
import FilterWrap from '../../components/Formik/Forms/FilterWrap';
import NoResultsTable from '../../components/NoResultsTable';
import Spacer from '../../components/Spacer';
import { TableCellHead } from '../../components/TableCellHead';
import TextInput from '../../components/TextInput';
import { HandleErrorPage } from '../../utils/handleErrorPage';
import { useAddresses } from './hooks/useAddresses';
import { AddressDetail } from './types';

interface Props {
  list: AddressDetail[];
  loading: boolean;
  totalCount: number;
  checks?: {
    ids: number[];
    onCheck: (id: number) => void;
  };
  authorization?: {
    canCreate: boolean;
    canDelete: boolean;
    canUpdate: boolean;
  };
}

type AddressQueryFilter = {
  description: string;
};

const AddressFilter = () => {
  const { clear, push } = useQueryParams<AddressQueryFilter>();

  const handleClearFilter = () => {
    clear();
  };

  const handleSubmitFilter = (values: AddressQueryFilter & { page: number }) => {
    push(values);
  };

  return (
    <FilterWrap
      initialValues={{
        description: '',
        page: 1,
      }}
      onSubmit={handleSubmitFilter}
      onClear={handleClearFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput name="description" label="Descrição" />
        </Grid>
      </Grid>
    </FilterWrap>
  );
};

export const AddressTable = ({ list, loading, totalCount, authorization }: Props) => {
  return (
    <>
      <AddressFilter />
      <Spacer y={4} />
      <Paper>
        <TableContainer loading={loading} totalCount={totalCount}>
          {list.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead label="Descrição" sort="description" />
                  <TableCellHead label="Endereço da Carteira" sort="address" />
                  <TableCellHead label="Rede da Carteira" sort="network" />
                  <TableCellHead label="Tipo do Endereço" sort="type" />
                  <TableCellHead label="E-mail" sort="" />
                  <TableCellHead label="Api Key" sort="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((address) => (
                  <TableRow hover key={address.id}>
                    <TableCell>
                      <LinkMD component={Link} to={`/addresses/${address.id}`}>
                        {address.description}
                      </LinkMD>
                    </TableCell>
                    <TableCell>{address.address}</TableCell>
                    <TableCell>{address.network}</TableCell>
                    <TableCell>{address.type}</TableCell>
                    <TableCell>{address.email}</TableCell>
                    <TableCell>
                      {address.apiKey.slice(0, 5) + '...' + address.apiKey.slice(-5)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={loading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path="addresses" authorization={authorization} />
    </>
  );
};

const AddressList = (props: any) => {
  const [state, actions] = useAddresses();

  useEffect(() => {
    setTimeout(() => {
      actions.fetchPage({});
    }, 1000);
  }, [actions.fetchPage]);

  useEffect(() => {
    setTimeout(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      actions.fetchPage(parse(params.toString()));
    }, 1000);
  }, [window.location.search]);

  switch (state.tag) {
    case 'empty':
      return <AddressTable totalCount={0} list={[]} loading={true} />;
    case 'with-data':
      return (
        <AddressTable list={state.list} totalCount={state.totalCount} loading={state.loading} />
      );
    case 'error':
      return <HandleErrorPage state={state.data as any} />;
  }
};

export default AddressList;
