import { useAppBar } from '../../../hooks';
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError';
import { mapReplaceFragment, useReplaceFragments } from '@elentari/core';
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset';
import { useTrader } from '../hooks/useTrader';
import { TraderTabs } from './TraderTabs';

export function TraderMasterDetail() {
  const [traderState, traderActions] = useTrader();
  const [, appBarActions] = useAppBar();
  useReplaceFragments(appBarActions.replaceFragment, [
    mapReplaceFragment(
      traderState,
      (u: any) => u.entity.id,
      (u: any) => u.entity.id,
    ),
  ]);
  useFetchAndReset(traderActions.fetch, traderActions.reset);
  return handleStateErrorsToRender(traderState, <TraderTabs />);
}
