import { Button, DialogActions, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { ReactNode } from 'react';

interface FilterWrapProps<T> {
  children: ReactNode;
  initialValues: T | null;
  validationSchema?: unknown | (() => unknown);
  backButtuon?: boolean;
  onSubmit(values: T): void;
  onClear(): void;
}

export const useFormStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  form: {
    width: '100%',
  },
  buttonsContainer: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
}));

export default function FilterWrap<T>({
  children,
  initialValues,
  validationSchema,
  onSubmit,
  onClear,
}: FilterWrapProps<T>) {
  const classes = useFormStyles();

  const handleReset = (resetForm: any) => {
    onClear();
    resetForm();
  };

  return initialValues ? (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formProps) => {
        return (
          <Paper className={classes.root}>
            <Form className={classes.form}>
              <>{children}</>
              <DialogActions className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReset.bind(null, formProps.resetForm)}
                >
                  Limpar
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Buscar
                </Button>
              </DialogActions>
            </Form>
          </Paper>
        );
      }}
    </Formik>
  ) : (
    <Skeleton variant="rect" width="100%" height={400} />
  );
}
