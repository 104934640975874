import { Box, Button, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { useField } from 'formik';
import { ChangeEvent } from 'react';

interface FileUploadInputProps {
  name: string;
  label: string;
  setFile: (file: File) => void;
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  fileName: {
    paddingLeft: theme.spacing(2),
  },
}));

function FileUploadInput(props: FileUploadInputProps) {
  const classes = useStyles();
  const { setFile, ...infoProps } = props;
  const [field, meta, helpers] = useField(infoProps);

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    try {
      if (files && files.length > 0) {
        const file = files[0];
        setFile(file);
        helpers.setValue(
          files?.length
            ? {
                name: file.name,
                path: file,
              }
            : null,
        );
      }
    } catch (error) {
      return error;
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <input
        accept=".pdf"
        onChange={handleChange}
        className={classes.input}
        id={props.name}
        type="file"
      />
      <label htmlFor={props.name}>
        <Button variant="outlined" color="primary" component="span">
          {props.label}
        </Button>
      </label>
      <Typography className={classes.fileName}>{field.value?.name}</Typography>
      <FormHelperText error={Boolean((meta.error as any)?.name)}>
        {(meta.error as any)?.name}
      </FormHelperText>
    </Box>
  );
}

export default FileUploadInput;
