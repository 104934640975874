import { TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';

interface TextInputProps {
  name: string;
  label: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  hyperactive?: boolean;
  debounce?: boolean;
  'data-cy'?: string;
  multiple?: boolean;
  minLength?: number;
  type?: 'string' | 'number';
}

function TextInput(props: TextInputProps) {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const { hyperactive, endAdornment } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.target.name = props.name;
    field.onChange(event);

    if (hyperactive) {
      formik.submitForm();
    }
  }

  return props.debounce ? (
    <DebounceInput
      debounceTimeout={500}
      element={TextField}
      {...field}
      {...props}
      multiple={props.multiple}
      minLength={props.minLength ? props.minLength : 1}
      variant="outlined"
      fullWidth={props.fullWidth ?? true}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment,
        name: props.name,
      }}
    />
  ) : (
    <TextField
      {...field}
      {...props}
      multiline={props.multiple}
      minRows={props.minLength ? props.minLength : 1}
      variant="outlined"
      fullWidth={props.fullWidth ?? true}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment,
      }}
    />
  );
}

export default TextInput;
