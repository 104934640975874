import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import When from './When';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
}));

type Props = {
  authorization?: { canCreate: boolean };
  path: string;
};

export const AddRecord = ({ authorization, path }: Props) => {
  const classes = useStyles();

  return (
    <When value={!authorization || authorization.canCreate} equals>
      <Tooltip title="Adicionar registro" aria-label="Adicionar registro">
        <Fab
          component={Link}
          to={`${path}/new`}
          color="primary"
          aria-label="Add"
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </When>
  );
};
