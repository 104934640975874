import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SimpleDialog from './SimpleDialog';

type Props = {
  onInactivate: () => void;
  title?: string;
  message?: string;
  disabled?: boolean;
};

export function InactivateReplicationRecord({
  onInactivate: onInactivate,
  title,
  message,
  disabled,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title="Inativar Replicação" aria-label="Inativar Replicação">
        <TableCell align="right" padding="checkbox">
          <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
            <CloseIcon />
          </IconButton>
        </TableCell>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente inativar essa replicação?'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onInactivate}
        title={title || 'Aprovar este Usuário'}
        primaryActionButtonLabel="Sim"
      />
    </>
  );
}
