export enum TermType {
  GENERAL = 'GENERAL',
  RISK = 'RISK',
  ACCOUNT_CANCEL = 'ACCOUNT_CANCEL',
}

export interface Term {
  id?: number;
  term: string;
  createdAt: Date;
  isActive: boolean;
  type: TermType;
}

export interface ITermForm {
  id?: number;
  term: string;
  type: TermType;
}

export interface TermDetail {
  id: number;
  term: string;
  createdAt: Date;
  isActive: boolean;
  type: TermType;
}
