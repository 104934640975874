export enum MessagesYup {
  MENSAGEM_OBRIGATORIO = 'O campo em específico é obrigatório',
  MENSAGEM_CAMPO_STRING = 'O campo em específico não aceita esses parâmetros em específico.',
  MENSAGEM_TIPO_NUMERICO = 'O campo em específico precisa ser numérico',
  MENSAGEM_VALOR_MINIMO = 'O valor mínimo para este campo é',
  MENSAGEM_VALOR_MAXIMO = 'O valor máximo para este campo é',
  MENSAGEM_VALOR_NEGATIVO = 'O valor mínimo não pode ser negativo',
  MENSAGEM_EMAIL = 'Formato inválido de e-mail',
  MENSAGEM_ERRO = 'O campo em específico é uma string, algo deu errado.',
  MENSAGEM_DATA_INVALIDA = 'Data inválida.',
  MENSAGEM_VALOR_ZERO = 'O valor mínimo para este campo deve ser maior que zero.',
  MENSAGEM_LIMTADA_ATE = 'O campo em específico contém limite de caracteres.',
  MESSAGEM_VALOR_CELULAR_MENOR = 'O valor mínimo para este campo deve ser maior que os 11 dígitos',
  MESSAGEM_VALOR_CPF_INVALIDO = 'O CPF Possui um valor inválido.',
  MENSAGEM_CPF_LIMTADA = 'O campo CPF em específico contém limite de 11 caracteres.',
  INVALID_DATE = 'Data inválida!',
  INVALID_RG = 'RG inválido!',
  MIN_PASS = 'A senha deve conter, no mínimo, 6 caracteres!',
  CPF_MIN = 'O CPF deve conter 11 caracteres!',
  INVALID_CNPJ = 'CNPJ inválido!',
  CNPJ_MIN = 'O CNPJ deve conter 14 caracteres!',
  TEL_MIN = 'O Telefone deve conter 11 caracteres!',
  CEP_MIN = 'O CEP deve conter 9 caracteres!',
  RG_MIN = 'O RG deve conter 9 caracteres!',
  LOGIN_ERROR = 'Usuário ou senha inválidos.',
  PASSWORD_MISS_MATCH = 'As senhas devem ser iguais.',
  INVALID_URL = 'Endereço URL inválido!',
}
