import { useField } from 'formik';
import { Tooltip } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { InputLabelProps } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface DateInputProps {
  name: string;
  label: string;
  disabled?: boolean;
  InputLabelProps?: Partial<InputLabelProps>;
}

function DateInput(props: DateInputProps) {
  const [field, meta, helpers] = useField(props);
  const { label, disabled } = props;

  function handleChange(date: MaterialUiPickersDate) {
    helpers.setValue(date);
  }

  return (
    <KeyboardDatePicker
      value={field.value}
      format="dd/MM/yyyy"
      onChange={handleChange}
      inputVariant="outlined"
      label={label}
      disabled={disabled}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      keyboardIcon={
        <Tooltip title="Selecionar data">
          <EventIcon />
        </Tooltip>
      }
      fullWidth
      cancelLabel="Cancelar"
      InputLabelProps={props.InputLabelProps}
    />
  );
}

export default DateInput;
