import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import InputMask from 'react-input-mask';

interface MaskInputProps {
  disabled?: boolean;
  name: string;
  label: string;
  mask: string;
  type?: string;
  endAdornment?: React.ReactNode;
}

function MaskInput(props: MaskInputProps) {
  const [field, meta] = useField(props);
  const { label, mask, type, endAdornment } = props;

  return (
    <InputMask mask={mask} alwaysShowMask={false} {...field}>
      {(inputProps: unknown) => (
        <TextField
          {...inputProps}
          label={label}
          type={type}
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          fullWidth
          InputProps={{
            endAdornment,
            name: props.name,
            disabled: props.disabled,
          }}
        />
      )}
    </InputMask>
  );
}

export default MaskInput;
