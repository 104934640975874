import { makeUseList } from '@elentari/core';
import { makeUseSecondaryList } from '@elentari/core/hooks/makeUseList';
import {
  TraderReplicationCycleDetail,
  TraderReplicationMetricsDetail,
  TraderReplicationPositionDetail,
} from '../types';
import { replicationCycleMetricsRepository } from './replicationCycleMetricsRepository';
import { replicationCycleRepository } from './replicationCycleRepository';
import { traderPositionsRepository } from './traderPositionsRepository';
import { traderRepository } from './traderRepository';

export const useTraders = makeUseList(traderRepository.fetch);

export const useReplicationCycles = makeUseSecondaryList<TraderReplicationCycleDetail>(
  replicationCycleRepository.fetch,
);

export const useReplicationCyclesMetrics = makeUseSecondaryList<TraderReplicationMetricsDetail>(
  replicationCycleMetricsRepository.fetch,
);

export const useTraderPositions = makeUseSecondaryList<TraderReplicationPositionDetail>(
  traderPositionsRepository.fetch,
);
