import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: 'Gilroy',
  },
  palette: {
    primary: {
      main: '#F3BA2F',
    },
    secondary: {
      main: '#d4aa2a',
    },
    white: '#ffffff',
    grey: grey,
    text: {
      primary: '#3A3A3A',
      secondary: '#676767',
    },
  },
});
