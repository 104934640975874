export enum VideoType {
  FUTURES_ACCOUNT = 'FUTURES_ACCOUNT',
  API_KEYS = 'API_KEYS',
  DEPOSIT = 'DEPOSIT',
}

export const videoTypeOptions = [
  { label: 'Conta de Futuros', value: VideoType.FUTURES_ACCOUNT },
  { label: 'Chaves API Binance', value: VideoType.API_KEYS },
  { label: 'Depósito', value: VideoType.DEPOSIT },
];

export interface Video {
  id?: number;
  url: string;
  type: VideoType;
}

export interface IVideoForm {
  id?: number;
  url: string;
  type: VideoType;
}

export interface VideoDetail {
  id: number;
  url: string;
  type: VideoType;
}
