import { TextField, InputAdornment } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useField } from 'formik';

interface TextInputProps {
  name: string;
  label: string;
  disabled?: boolean;
}

function EmailInput(props: TextInputProps) {
  const [field, meta] = useField(props);

  return (
    <TextField
      variant="outlined"
      fullWidth
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default EmailInput;
