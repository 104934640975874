import { EdgesPage } from '@elentari/core/types';
import { makeSubRepository } from '@elentari/core/utils/makeRepository';
import { ParsedQs } from 'qs';
import { apiSauceInstance } from '../../../services/api';
import { paginationParams } from '../../../utils/paginationQueryParams';
import { PendingReplicationBoundDetail } from '../types';

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params;
  return { ...params, ...paginationParams(rest) };
};

export const replicationBoundRepository = makeSubRepository<
  EdgesPage<PendingReplicationBoundDetail>,
  { id: number | undefined },
  PendingReplicationBoundDetail,
  number,
  number
>({ resource: 'replication-cycle/:id/replication', mapQueryParams }, apiSauceInstance);
